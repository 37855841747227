import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {appSettings} from '../app-settings';
import {Observable} from 'rxjs';
import moment from 'moment';
import {Configuration} from '../configuration';
import {switchMap, tap, toArray} from 'rxjs/operators';
import {AdvancedCustomer, CustomerItem} from '../models/customer-item.model';
import {Profile, TemplateProfile, UserI, UserProfileI} from '../models/userI';
import {Pagination} from '../models/pagination';
import {Intestatario} from '../models/fiche.model';

@Injectable({
    providedIn: 'root'
})
export class ClientiAppService {
    constructor(private http: HttpClient) {
    }

    getCustomers(filters: IGetCustomerFilters, isPureRequest: boolean = false) {
        let headers = new HttpHeaders();
        if (isPureRequest)
            headers = headers.set('pure-request', 'true');

        let params = new HttpParams();

        params = filters.search ? params.set('search', filters.search) : params;
        params = filters.group ? params.set('group', filters.group) : params;
        params = filters.startFrom ? params.set('startFrom', moment(filters.startFrom).toISOString()) : params;
        params = filters.from ? params.set('from', moment(filters.from).toISOString()) : params;
        params = filters.to ? params.set('to', moment(filters.to).toISOString()) : params;
        params = filters.basicFilters ? params.set('basicFilters', filters.basicFilters) : params;
        params = filters.basicSubFilters ? params.set('basicSubFilters', filters.basicSubFilters) : params;
        params = filters.activityState ? params.set('activityState', filters.activityState) : params;
        params = filters.orderBy ? params.set('orderBy', filters.orderBy) : params;
        params = filters.services ? params.appendAll({services: filters.services}) : params;
        params = filters.isAdvancedFiltering ? params.set('isAdvancedFiltering', filters.isAdvancedFiltering) : params;
        params = filters.selectedCollaboratorId ? params.set('selectedCollaboratorId', filters.selectedCollaboratorId) : params;
        params = filters.mustContainAllServices ? params.set('mustContainAllServices', filters.mustContainAllServices) : params;

        return this.http.get<AdvancedCustomer[]>(`${appSettings.API.CUSTOMERS}/customers`, {
            headers: headers,
            params: params
        });
    }

    getIntestatari(body: Pagination) {
        const isDeleted = body.isDeleted;

        return this.http.post<Intestatario[]>(`${appSettings.API.CUSTOMERS}/GetIntestatari/${isDeleted}`, body);
    }

    getCustomer(customerId: number): Observable<CustomerItem> {
        return this.http.get<CustomerItem>(`${appSettings.API.CUSTOMERS}/${customerId}`);
    }

    getCustomerByTosToken(tosToken: string): Observable<CustomerItem> {
        return this.http.get<CustomerItem>(`${appSettings.API.CUSTOMERS}/tos-token/${tosToken}`);
    }

    getAdvancedCustomer(customerId: number, isPureRequest = false): Observable<AdvancedCustomer> {
        let headers = new HttpHeaders();
        if (isPureRequest)
            headers = headers.set('pure-request', 'true');
        return this.http.get<AdvancedCustomer>(`${appSettings.API.CUSTOMERS}/${customerId}/advanced`,
            {
                headers: headers
            });
    }


    updateCustomer(client: CustomerItem): Observable<CustomerItem> {
        if (client && client.id === 0) {
            return this.http.post<CustomerItem>(`${appSettings.API.CUSTOMERS}`, client);
        }
        return this.http.put<CustomerItem>(`${appSettings.API.CUSTOMERS}/${client.id}`, client);
    }

    deleteCustomer(idClient: string | number) {
        return this.http.delete<CustomerItem>(`${appSettings.API.CUSTOMERS}/${idClient}`);
    }

    union(body: any) {
        return this.http.post(`${appSettings.API.CUSTOMERS}/merge`, body);
    }

    acceptTOS(customerId: number) {
        return this.http.patch<CustomerItem>(`${appSettings.API.CUSTOMERS}/${customerId}/accept-tos`, {});
    }

    sendTosRequest(customerId: number, saloneName: string) {
        let params = new HttpParams();
        params = params.set('saloneName', saloneName);
        return this.http.post<void>(`${appSettings.API.CUSTOMERS}/${customerId}/send-tos-request`, {},
            {
                params: params
            });
    }

    saveDocument(idClient: number, nameFile: string, file: File) {
        const formData: FormData = new FormData();
        formData.append('uploadFile', file, nameFile);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        return this.http.post(`${Configuration.getString('wsApiUrl')}/api/UploadMateriali/${idClient}`,
            formData,
            {headers}
        );
    }

    getUsers() {
        return this.http.get<UserI[]>(
            `${appSettings.APISALONMENU.API}/clienti/RegistrationsByStatus`
        ).pipe(
            switchMap(array => array),
            tap(e => e.Cliente.Orari = (e.Cliente.Orari ? JSON.parse(e.Cliente.Orari) : {})),
            toArray()
        );
    }

    updateCliente(user: Profile) {
        return this.http.put(
            `${Configuration.getString('wsApiUrl')}/api/clienti/ByCliente`,
            user
        );
    }

    getProfili() {
        return this.http.get<UserProfileI[]>(
            `${Configuration.getString('wsApiUrlSalonMenu')}/api/ProfiliClienti`
        );
    }

    putProfiloUser(user: Profile) {
        return this.http.put(
            `${Configuration.getString('wsApiUrl')}/api/clienti/Profili`, user);
    }

    confirmRegistration(user: Profile) {
        return this.http.put(
            `${Configuration.getString('wsApiUrl')}/api/clienti/Registrations`,
            user
        );
    }

    deleteRegistration(userId: number) {
        return this.http.delete(
            `${Configuration.getString('wsApiUrl')}/api/clienti/Registrations/${userId}`
        );
    }

    recoverRegistration(user: Profile) {
        return this.http.put(
            `${Configuration.getString('wsApiUrl')}/api/clienti/Registrations/${user.Id}`,
            user
        );
    }

    getUtentiXls() {
        // @ts-ignore
        return this.http.get<string>(`${Configuration.getString('wsApiUrl')}/api/report/clienti`, {responseType: 'blob'});
    }

    putProfilo(profile: UserProfileI) {
        return this.http.put(
            `${appSettings.API.API}/ProfiliClienti?id=${profile.Id}`, profile
        );
    }

    postProfilo(profile: UserProfileI) {
        return this.http.post(
            `${appSettings.API.API}/ProfiliClienti`, profile
        );
    }

    putProfiloTemplates(body: TemplateProfile[]) {
        return this.http.put(
            `${appSettings.API.API}/ProfiliClienti/PutTemplateProfili`, body
        );
    }

    deleteProfilo(id: number) {
        return this.http.delete(
            `${appSettings.API.API}/ProfiliClienti?id=${id}`
        );
    }

    deleteProfiloTemplates(id: number) {
        return this.http.delete<any>(appSettings.API.API + '/ProfiliClienti/deletetemplateprofili?id=' + id);
    }

    sendForm(param: { idUser: number; cap: string; phone: string; city: string; name: string; email: string; lastname: string }) {
        return this.http.post(`${appSettings.API.API}/form/${param.idUser}`,
            param);
    }
}

export interface IGetCustomerFilters {
    search?: string;
    group?: string,
    startFrom?: Date,
    from?: Date,
    to?: Date,
    basicFilters?: number,
    basicSubFilters?: number;
    activityState?: number,
    services?: number[],
    selectedCollaboratorId?: number,
    orderBy?: number,
    mustContainAllServices?: boolean;
    isAdvancedFiltering: boolean;

}
