import {Component, OnInit} from '@angular/core';
import {map, switchMap, toArray} from 'rxjs/operators';
import {ModalController} from '@ionic/angular';
import {ClientiAppService} from '../../../../services/clientiapp.service';
import {UserI} from '../../../../models/userI';
import {LoadingService} from '../../../../services/loading.service';

@Component({
    selector: 'app-permissions-users',
    templateUrl: './permissions-users.component.html',
    styleUrls: ['./permissions-users.component.scss'],
})
export class PermissionsUsersComponent implements OnInit {
    search = '';
    users: UserI[];

    constructor(
        private modalCtrl: ModalController,
        private clientiAppService: ClientiAppService,
        private loadingService: LoadingService,
    ) {
    }

    ngOnInit() {
        this.loadingService.show();
        this.clientiAppService.getUsers().pipe(
            switchMap(array => array),
            map(el => ({...el})),
            toArray()
        ).subscribe(users => {
            this.users = users;
            this.loadingService.hide();
        });
    }

    close() {
        this.modalCtrl.dismiss();
    }
}
