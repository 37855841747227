import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {PhotoI} from '../PhotoI';
import {UsersService} from '../../../services/users.service';
import {PhotoStateEnum} from '../PhotoStateEnum';
import {map, shareReplay} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {AlbumPhotosComponent} from './album-photos/album-photos.component';
import {PhotoDetailComponent} from '../photo-detail/photo-detail.component';
import {GalleryService} from 'src/app/services/gallery.service';
import {MenusService} from '../../../services/menus.service';
import {PageTemplateGalleryFilesVModel} from '../../../models/page-template-gallery-files-v.model';
import {GalleryFileVModel} from '../../../models/gallery-file-v.model';
import {IAlbum} from '../../../models/IAlbum';
import {TagCategory} from '../../../models/tag-category';

@Component({
    selector: 'app-archive',
    templateUrl: './archive.component.html',
    styleUrls: ['./archive.component.scss'],
})
export class ArchiveComponent implements OnInit {
    @ViewChild(AlbumPhotosComponent, {static: false}) albumPhotosComponent: AlbumPhotosComponent;
    @Input() albums: IAlbum[];
    @Input() isModal = false;
    @Input() isSede = false;
    @Input() groupByTemplate = false;
    @Input() search: string;
    @Input() albumSelected: IAlbum;
    @Input() macroTagsSelected: TagCategory[];
    @Output() albumSelectedChange = new EventEmitter<IAlbum>();
    photos$: Observable<PhotoI[]> = null;
    widthCalc = 160;
    itemsLoad = 3;
    photosByTemplate: { temp: string, photos: PhotoI[] }[] = [];
    pageTemplatesGalleryFiles$: Observable<PageTemplateGalleryFilesVModel[]>;
    removedGalleryFiles$ = new BehaviorSubject(new Map<number, GalleryFileVModel>());

    constructor(
        private modalCtrl: ModalController,
        private _galleryService: GalleryService,
        public usersService: UsersService,
        private menusService: MenusService
    ) {
    }

    get currentUser() {
        return this.usersService.currentUserInfo;
    }

    ngOnInit() {
        if (window.innerWidth < 600) {
            this.widthCalc = (window.innerWidth - 56) / 2;
        }

        const state = (this.menusService.currentMenu.IsSharingAllowed) ? PhotoStateEnum.archiveSede : PhotoStateEnum.archiveAll;
        // this.photos$ = this.httpService.getPhotosLibrary(state, true).pipe(
        //   switchMap(array => array),
        //   map((e) => ({...e, AlbumModel: (!e.AlbumModel ? {Id: null, Nome: '', Anno: ''} : e.AlbumModel)})),
        //   toArray(),
        //   publishReplay(),
        //   refCount()
        // );
        // if (this.groupByTemplate) {
        //   this.photos$.subscribe(photos => {
        //     this.photosByTemplate = [];
        //     const templates = Array.from(new Set(photos.map(e => e.NomeTemplate)));
        //     for (const temp of templates) {
        //       this.photosByTemplate.push({
        //         temp, photos: photos.filter(e => e.NomeTemplate === temp)
        //       });
        //     }
        //   });
        // }

        const filesAndRemoved$ = combineLatest(
            [
                this._galleryService.getGalleryFilesForSedeArchive().pipe(
                    map(ts => ts.map(t => new PageTemplateGalleryFilesVModel(t)))
                ),
                this.removedGalleryFiles$
            ]
        )

        this.pageTemplatesGalleryFiles$ = filesAndRemoved$.pipe(
            map(([ts, removed]) => ts.map(t => {
                t.galleryFiles = t.galleryFiles.filter(f => !removed.has(f.file.Id))
                return t
            })),
            shareReplay(1)
        )
    }

    close(reload = false) {
        this.modalCtrl.dismiss(reload);
    }

    clickItem(albumPhoto: { album: IAlbum; photos: PhotoI[] }) {
        const {album} = albumPhoto;
        this.albumSelected = album;
        this.albumSelectedChange.emit(this.albumSelected);
    }

    loadData(event: any) {
        this.itemsLoad += 2;
        setTimeout(() => {
            event.target.complete();
        }, 500);
    }

    refresh() {
        if (this.albumPhotosComponent) {
            this.albumPhotosComponent.ngOnInit();
        }
    }

    openImage(item: PhotoI, photos: PhotoI[]) {
        if (this.isModal) {
            this.modalCtrl.dismiss(item);
        } else {
            const indexSelected = photos.findIndex(e => e.Name === item.Name);
            this.modalCtrl.create({
                component: PhotoDetailComponent,
                componentProps: {photos, indexSelected},
                cssClass: 'full-modal'
            }).then((modal) => {
                modal.present();
                modal.onDidDismiss().then(() => {
                    this.refresh();
                });
            });
        }
    }

    onGalleryFileClick(file: GalleryFileVModel, template: PageTemplateGalleryFilesVModel) {
        if (this.isModal) {
            this.modalCtrl.dismiss(file.toPhotoModel());
            return;
        }
        this._galleryService.setLocalGalleryFiles(template.galleryFiles);
    }

    onGalleryFileDelete(file: GalleryFileVModel) {
        this.removedGalleryFiles$.value.set(file.file.Id, file);
        this.removedGalleryFiles$.next(this.removedGalleryFiles$.value);
    }

    isSharingAllowed() {
        return this.menusService.currentMenu.IsSharingAllowed
    }
}
