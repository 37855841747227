import { CatalogItemImage } from "./catalog-item-image";
import { CatalogItemServiceCategory, ICatalogItemServiceCategoryDto } from "./catalog-item-service-category";
import { CatalogItemServiceListContainer, ICatalogItemServiceListContainerDto } from "./catalog-item-servicelist-container";
import { CatalogItemText, CatalogItemTextFontAlign, CatalogItemTextFontStyle, CatalogItemTextFontWeight } from "./catalog-item-text";
import { CatalogPage, ICatalogPageDto } from "./catalog-page";


export interface ICatalogPageServiceListDto extends ICatalogPageDto {
    CategoryConfig: ICatalogServiceListConfigDto;
    ServiceConfig: ICatalogServiceListConfigDto;
    CategoryItems: ICatalogItemServiceCategoryDto[];
    ServiceListContainer: ICatalogItemServiceListContainerDto;

}

export class CatalogPageServiceList extends CatalogPage{
    public static createFromDto(dto: ICatalogPageServiceListDto){
        var page = new CatalogPageServiceList();

        page.id = dto.Id;
        page.name = dto.Name;
        page.order = dto.Order;
        page.isPublished = dto.IsPublished;
        page.templateId = dto.TemplateId;
        page.categoryConfig = CatalogServiceListConfig.createFromDto(dto.CategoryConfig);
        page.serviceConfig = CatalogServiceListConfig.createFromDto(dto.ServiceConfig);
        page.serviceListContainer = CatalogItemServiceListContainer.createFromDto( () => page.isModified = true, dto.ServiceListContainer);

        page.imageItems = dto.ImageItems.map(i => CatalogItemImage.createFromDto( () => page.isModified = true, i));
        page.textItems = dto.TextItems.map(t => CatalogItemText.createFromDto(() => page.isModified = true, t))
        page.categoryItems = dto.CategoryItems
            .map(c => CatalogItemServiceCategory.createFromDto( () => page.isModified = true, c, page.categoryConfig, page.serviceConfig))

        return page;
    }

    categoryConfig: CatalogServiceListConfig;
    serviceConfig: CatalogServiceListConfig;
    serviceListContainer: CatalogItemServiceListContainer;

    categoryItems: CatalogItemServiceCategory[] = [];

    private constructor() {
        super();
        this.isServiceListPage = true;
    }

    toDto(): ICatalogPageServiceListDto{
        const page:ICatalogPageServiceListDto =  {
            IsGeneralPage: false,
            IsServiceListPage: true,
            IsServicePage: false,
            Id: this.id,
            Name: this.name,
            Order: this.order,
            IsPublished: this.isPublished,
            TemplateId: this.templateId,
            ImageItems: this.imageItems.map(i => i.toDto()),
            TextItems: this.textItems.map(i => i.toDto()),
            CategoryItems: this.categoryItems.map(c => c.toDto()),
            CategoryConfig: this.categoryConfig.toDto(),
            ServiceConfig: this.serviceConfig.toDto(),
            ServiceListContainer: this.serviceListContainer.toDto(),
        }
        return page;
    }
}



export interface ICatalogServiceListConfigDto{
    LeftPadding: number;
    BottomPadding: number;
    FontSize: number;
    FontColor: string;
    FontAlign: CatalogItemTextFontAlign;
    FontStyle: CatalogItemTextFontStyle;
    FontWeight: CatalogItemTextFontWeight;
    FontFamily: string;
    LineHeight?: number;
    LetterSpacing: number;
    BackgroundColor?: string;
    Opacity?: number;
    BorderWidth?: number;
    BorderColor?: string;
}

export class CatalogServiceListConfig{

    public static createFromDto(dto: ICatalogServiceListConfigDto){
        const config = new CatalogServiceListConfig();

        config.leftPadding = dto.LeftPadding;
        config.bottomPadding = dto.BottomPadding;
        config.fontSize = dto.FontSize;
        config.fontColor = dto.FontColor;
        config.fontAlign = dto.FontAlign;
        config.fontStyle = dto.FontStyle;
        config.fontWeight = dto.FontWeight;
        config.fontFamily = dto.FontFamily;
        config.lineHeight = dto.LineHeight;
        config.letterSpacing = dto.LetterSpacing;
        config.backgroundColor = dto.BackgroundColor;
        config.opacity = dto.Opacity;
        config.borderWidth = dto.BorderWidth;
        config.borderColor = dto.BorderColor;

        return config
    }

    leftPadding: number;
    bottomPadding: number;
    fontSize: number;
    fontColor: string;
    fontAlign: CatalogItemTextFontAlign;
    fontStyle: CatalogItemTextFontStyle;
    fontWeight: CatalogItemTextFontWeight;
    fontFamily: string;
    lineHeight?: number;
    letterSpacing: number;
    backgroundColor?: string;
    opacity?: number;
    borderWidth?: number;
    borderColor?: string;

    private constructor() {

    }

    public toDto(): ICatalogServiceListConfigDto{
        return {
            LeftPadding: Math.round( this.leftPadding),
            BottomPadding: Math.round( this.bottomPadding),
            BackgroundColor: this.backgroundColor,
            BorderColor: this.borderColor,
            BorderWidth: this.borderWidth,
            Opacity: this.opacity,
            FontSize: this.fontSize,
            FontColor: this.fontColor,
            FontAlign: this.fontAlign,
            FontStyle: this.fontStyle,
            FontWeight: this.fontWeight,
            FontFamily: this.fontFamily,
            LineHeight: this.lineHeight,
            LetterSpacing: this.letterSpacing,
        }
    }
}


