import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { interval } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class CheckUpdateService {
	constructor(
		public updates: SwUpdate,
		public alertCtrl: AlertController,
		private router: Router,
	) {
		const timePick = 60 * 1000;

		if (updates.isEnabled) {
			interval(timePick).subscribe(() => updates.checkForUpdate().then(() => console.log('Checking for updates...')));
		}
	}

	public checkForUpdates(): void {
		this.updates.versionUpdates
			.pipe(
				tap((e) => console.log('Version update available:', e)),
				filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
			)
			.subscribe(() => {
				this.updateApp();
			});
	}

	updateApp() {
		this.alertCtrl
			.create({
				header: 'Agenda è stata aggiornata!',
				backdropDismiss: false,
				message: `Premere OK per confermare.`,
				buttons: [
					{
						text: 'Ok',
						handler: () => {
							console.log('Updating to new version...');
							this.updates.activateUpdate().then(() => {
								this.router.navigateByUrl('/login').then(() => {
									window.location.reload();
								});
							});
						},
					},
				],
			})
			.then((ElRef) => ElRef.present());
	}
}
