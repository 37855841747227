import { CatalogItemImage, ICatalogItemImageDto } from "./catalog-item-image";
import { CatalogItemText, CatalogItemTextFontAlign, CatalogItemTextFontStyle, CatalogItemTextFontWeight, ICatalogItemTextDto } from "./catalog-item-text";

export interface ICatalogPageDto {
    IsServicePage: boolean;
    IsServiceListPage: boolean;
    IsGeneralPage: boolean;
    TemplateId: number;
    Id: string;
    Name: string;
    Order: number;
    IsPublished: boolean;
    ImageItems: ICatalogItemImageDto[];
    TextItems: ICatalogItemTextDto[];

}

export abstract class CatalogPage{
    isServicePage: boolean;
    isServiceListPage: boolean;
    isGeneralPage: boolean;
    templateId: number;
    id: string;
    name: string;
    order: number;
    isPublished: boolean;
    isModified: boolean;
    imageItems: CatalogItemImage[];
    textItems: CatalogItemText[];
}
