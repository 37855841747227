
export interface ICatalogItemTextDto{
    Id: number;
    CatalogPageId: string;
    Name: string;
    Order: number;
    Width: number;
    Height: number;
    Left: number;
    Top: number;
    Text?: string;
    Text_en?: string;
    Text_es?: string;
    Text_pt?: string;
    Text_fr?: string;
    Text_de?: string;
    FontSize: number;
    FontColor: string;
    FontAlign: 'left' | 'center' | 'right';
    FontStyle: 'oblique' | 'normal' | 'italic';
    FontWeight: 'lighter' | 'bold' | 'normal';
    FontFamily: string;
    LineHeight: number;
    LetterSpacing: number;
    BackgroundColor?: string;
    Opacity?: number;
    BorderWidth?: number;
    BorderColor?: string;
    IsLocked?: boolean;
    IsVisible: boolean;
}

export class CatalogItemText{

    static create(onModify: (() => void), pageId: string): CatalogItemText{
        var item = new CatalogItemText();

        item.onModify = onModify;
        item.height = 100;
        item.left = 100;
        item.top = 100;
        item.width = 100;
        item.catalogPageId = pageId;

        return item;
    }

    static createFromDto(onModify: (() => void), dto: ICatalogItemTextDto): CatalogItemText{
        var item = new CatalogItemText();

        item.onModify = onModify;

        item.id = dto.Id;
        item.catalogPageId = dto.CatalogPageId;
        item.name = dto.Name;
        item.order = dto.Order;
        item.width = dto.Width;
        item.height = dto.Height;
        item.left = dto.Left;
        item.top = dto.Top;
        item.text = dto.Text;
        item.fontSize = dto.FontSize;
        item.fontColor = dto.FontColor;
        item.fontAlign = dto.FontAlign;
        item.fontStyle = dto.FontStyle;
        item.fontWeight = dto.FontWeight;
        item.fontFamily = dto.FontFamily;
        item.lineHeight = dto.LineHeight;
        item.letterSpacing = dto.LetterSpacing;
        item.backgroundColor = dto.BackgroundColor;
        item.opacity = dto.Opacity;
        item.borderWidth = dto.BorderWidth;
        item.borderColor = dto.BorderColor;
        item.isLocked = dto.IsLocked;
        item.isVisible = dto.IsVisible;

        return item;
    }

    public isNew = false;
    id: number;
    catalogPageId: string;
    name: string;
    order: number;
    width: number;
    height: number;
    left: number;
    top: number;
    text?: string;
    fontSize: number = 14;
    fontColor: string = 'black';
    fontAlign: CatalogItemTextFontAlign = 'left';
    fontStyle: CatalogItemTextFontStyle = 'normal';
    fontWeight: CatalogItemTextFontWeight = 'normal';
    fontFamily: string = 'Comfortaa';
    lineHeight: number = 13;
    letterSpacing: number = 1;
    backgroundColor?: string = 'transparent';
    opacity?: number = 1;
    borderWidth?: number = 0;
    borderColor?: string = 'lightgray';
    isLocked: boolean = false;
    isVisible: boolean = true;


    private _isModified: boolean;
    get isModified(){ return this._isModified};
    set isModified(value: boolean){
        this._isModified = value;
        this.onModify();
    }

    private onModify: (() => void);
    private constructor() {

    }

    public toDto(): ICatalogItemTextDto{
        return {
            Id: this.id,
            CatalogPageId: this.catalogPageId,
            Name: this.name,
            BackgroundColor: this.backgroundColor,
            BorderColor: this.borderColor,
            BorderWidth: this.borderWidth,
            Height: Math.round( this.height),
            Top: Math.round( this.top),
            Left: Math.round( this.left),
            Width: Math.round( this.width),
            IsLocked: this.isLocked,
            IsVisible: this.isVisible,
            Opacity: this.opacity,
            Order: this.order,
            FontSize: this.fontSize,
            FontColor: this.fontColor,
            FontAlign: this.fontAlign,
            FontStyle: this.fontStyle,
            FontWeight: this.fontWeight,
            FontFamily: this.fontFamily,
            LineHeight: this.lineHeight,
            LetterSpacing: this.letterSpacing,
            Text: this.text,
        }
    }
}

export type CatalogItemTextFontStyle = 'oblique' | 'normal' | 'italic';
export type CatalogItemTextFontAlign = 'left' | 'center' | 'right';
export type CatalogItemTextFontWeight = 'lighter' | 'bold' | 'normal';
