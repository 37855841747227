export class ICatalogItemImageDto{
    Id: number;
    CatalogPageId: string;
    Name: string;
    Order: number;
    Width: number;
    Height: number;
    Left: number;
    Top: number;
    BackgroundColor: string;
    Opacity: number;
    BorderWidth: number;
    BorderColor: string;
    IsLocked: boolean;
    IsVisible: boolean;
    SelectedImageId: string;
    Images:  ICatalogItemImageUpload[];
    NewImageIds: string[];
}

export class CatalogItemImage{

    public static create(onModify: (() => void), pageId: string, order: number): CatalogItemImage{
        const item = new CatalogItemImage();
        item.catalogPageId = pageId;
        item.name = 'image'+ order;
        item.order = order;
        item.width = 100;
        item.height = 100
        item.left = 100;
        item.top = 100;
        item.opacity = 1;
        item.isVisible = true;
        item.onModify = onModify;

        return item;
    }

    public static createFromDto(onModify: (() => void), dto: ICatalogItemImageDto): CatalogItemImage{
        const item = new CatalogItemImage();
        item.id = dto.Id;
        item.catalogPageId = dto.CatalogPageId;
        item.name = dto.Name;
        item.width = dto.Width;
        item.height = dto.Height;
        item.left = dto.Left;
        item.top = dto.Top;
        item.order = dto.Order;
        item._images = dto.Images;
        item.borderColor = dto.BorderColor;
        item.borderWidth = dto.BorderWidth;
        item.opacity = dto.Opacity;
        item.backgroundColor = dto.BackgroundColor;
        item.isLocked = dto.IsLocked;
        item.isVisible = dto.IsVisible;

        item.selectedImage = dto.Images.find(i => i.Id === dto.SelectedImageId);
        item.onModify = onModify;

        return item;
    }

    public isNew = false;
    public id: number;
    public catalogPageId: string = null;
    public name: string = '';
    public width: number = 150;
    public height: number = 50;
    public left: number = 0;
    public top: number = 0;
    public order: number = 0;
    public selectedImage: ICatalogItemImageUpload = null;
    public borderColor: string = 'lightgray';
    public borderWidth: number = 0;
    public opacity: number = 1;
    public backgroundColor: string = 'transparent';
    public isLocked: boolean = false;
    public isVisible: boolean = true;
    public newImageIds: string[] = [];

    public _images: ICatalogItemImageUpload[] = [];
    get images(): readonly ICatalogItemImageUpload[]{ return this._images};

    private _isModified: boolean;
    get isModified(){ return this._isModified};
    set isModified(value: boolean){
        this._isModified = value;
        this.onModify();
    }

    private onModify: (() => void);
    private constructor(  ) { }

    public addNewImage(image: ICatalogItemImageUpload){
        this._images = [image, ...this.images];
        this.newImageIds = [image.Id, ...this.newImageIds];
    }

    public removeImage(image: ICatalogItemImageUpload){
        this._images = this.images.filter(i => i.Id !== image.Id);
        this.newImageIds = this.newImageIds.filter(i => i !== image.Id);
    }
    public toDto(): ICatalogItemImageDto{
        return {
            Id: this.id,
            CatalogPageId: this.catalogPageId,
            Name: this.name,
            BackgroundColor: this.backgroundColor,
            BorderColor: this.borderColor,
            BorderWidth: this.borderWidth,
            Height: Math.round( this.height),
            IsLocked: this.isLocked,
            IsVisible: this.isVisible,
            Left: Math.round( this.left),
            Opacity: this.opacity,
            Order: this.order,
            SelectedImageId: this.selectedImage.Id,
            Top: Math.round( this.top),
            Width: Math.round( this.width),
            Images: [],
            NewImageIds: this.newImageIds
        }
    }
}

export interface ICatalogItemImageUpload{
    Id: string
    CatalogItemId: number
    Url: string
}

export interface ICreateCatalogItemImageRequest{
    CatalogPageId: string,
    Base64Data: string,
    FileName: string,
}
