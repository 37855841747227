import { CatalogItemImage } from "./catalog-item-image";
import { CatalogItemText } from "./catalog-item-text";
import { CatalogPage, ICatalogPageDto } from "./catalog-page";


export interface ICatalogPageGeneralDto extends ICatalogPageDto {

}

export class CatalogPageGeneral extends CatalogPage{
    public static createFromDto(dto: ICatalogPageGeneralDto){
        var page = new CatalogPageGeneral();

        page.id = dto.Id;
        page.name = dto.Name;
        page.order = dto.Order;
        page.isPublished = dto.IsPublished;
        page.templateId = dto.TemplateId;
        page.imageItems = dto.ImageItems.map(i => CatalogItemImage.createFromDto( () => page.isModified = true, i));
        page.textItems = dto.TextItems.map(t => CatalogItemText.createFromDto(() => page.isModified = true, t))

        return page;
    }

    private constructor() {
        super();
        this.isGeneralPage = true;
    }

    toDto(): ICatalogPageGeneralDto{
        const page: ICatalogPageGeneralDto =  {
            IsGeneralPage: true,
            IsServiceListPage: false,
            IsServicePage: false,
            Id: this.id,
            Name: this.name,
            Order: this.order,
            IsPublished: this.isPublished,
            TemplateId: this.templateId,
            ImageItems: this.imageItems.map(i => i.toDto()),
            TextItems: this.textItems.map(i => i.toDto()),
        }
        return page;
    }
}

