import { Injectable } from '@angular/core';
import { IMenu } from '../models/menu';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { appSettings } from 'src/app/app-settings';
import { firstValueFrom, map } from 'rxjs';
import { IMenuPageServiceListDto, IUpdateMenuPageServiceListRequest } from '../models/menu-page-service-list';
import { IMenuPageGeneralDto, IUpdateMenuPageGeneralRequest } from '../models/menu-page-general';
import { IUpdateMenuPageRequestOld } from '../interfaces/IUpdateMenuPageRequestOld';
import {
	IMenuItemCategoryDtoOld,
	IMenuItemServiceDtoOld,
	MenuItemCategoryOld,
	MenuItemServiceOld,
} from '../models/menu-item-category';
import { IMenuItemServiceCategoryDto, MenuItemServiceCategory } from '../models/menu-item-service-category';
import { IMenuItemServiceDto, MenuItemService } from '../models/menu-item-service';
import { IMenuPage } from '../models/menu-page';
import { IMenuPageServiceDto } from '../models/menu-page-service';
import { ICreateMenuItemImageRequestOld } from '../interfaces/ICreateMenuItemImageRequestOld';
import { IMenuItemImageUpload } from '../interfaces/IMenuItemImageUpload';
import { IUpdateMenuPageServiceRequest } from '../interfaces/IUpdateMenuPageServiceRequest';
import { ICreateMenuItemImageRequest } from '../interfaces/ICreateMenuItemImageRequest';

@Injectable({
	providedIn: 'root',
})
export class MenusService {
	currentMenu: IMenu;

	constructor(private readonly http: HttpClient) {}

	getMenuById(menuId: number, groupFilterId?: string, isPureRequest: boolean = false) {
		console.log('get menu by id', groupFilterId);

		const paramsObj: any = {};
		if (groupFilterId) {
			paramsObj.clientGroupID = groupFilterId;
		}

		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return this.http
			.get<IMenu>(`${appSettings.APISALONMENU.MENUS}/${menuId}`, {
				headers: headers,
				params: new HttpParams({ fromObject: paramsObj }),
			})
			.pipe(
				map((m) => {
					m.Pages?.sort((a, b) => a.Ordering - b.Ordering);
					return m;
				}),
			);
	}

	getCurrentMenu(isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return this.http
			.get<IMenu>(
				`${appSettings.APISALONMENU.MENUS}/current`,
				// return this.http.get<IMenu>(`${appSettings.APISALONMENU.MENUS}/getAll`,
				{
					headers: headers,
				},
			)
			.pipe(
				map((m) => {
					m.Pages?.sort((a, b) => a.Ordering - b.Ordering);
					return m;
				}),
			);
	}

	getAllMenus(isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) {
			headers = headers.set('pure-request', 'true');
		}
		return this.http
			.get<IMenu[]>(`${appSettings.APISALONMENU.MENUS}/getAll`, {
				headers: headers,
			})
			.pipe(
				map((menus) => {
					menus.forEach((menu) => {
						if (menu.Pages) {
							menu.Pages.sort((a, b) => a.Ordering - b.Ordering);
						}
					});
					return menus;
				}),
			);
	}

	getMenuPageByIdOld(pageId: string, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return this.http.get<IMenuPage>(`${appSettings.APISALONMENU.MENUS}/pages/${pageId}`, {
			headers: headers,
		});
	}

	createMenuPageService(catalogId: string, menuId: number, serviceId: number, pageName: string) {
		return firstValueFrom(
			this.http.post<IMenuPageServiceDto>(`${appSettings.APISALONMENU.MENUS}/pages-service`, {
				CatalogPageId: catalogId,
				MenuId: menuId,
				ServiceId: serviceId,
				PageName: pageName,
			}),
		);
	}

	createMenuPageServiceList(catalogId: string, menuId: number, pageName: string) {
		return firstValueFrom(
			this.http.post<IMenuPageServiceListDto>(`${appSettings.APISALONMENU.MENUS}/pages-servicelist`, {
				CatalogPageId: catalogId,
				MenuId: menuId,
				PageName: pageName,
			}),
		);
	}

	createMenuPageGeneral(catalogId: string, menuId: number, pageName: string) {
		return firstValueFrom(
			this.http.post<IMenuPageGeneralDto>(`${appSettings.APISALONMENU.MENUS}/pages-general`, {
				CatalogPageId: catalogId,
				MenuId: menuId,
				PageName: pageName,
			}),
		);
	}

	updateMenuPageOld(request: IUpdateMenuPageRequestOld) {
		return this.http.put<IMenuPage>(`${appSettings.APISALONMENU.MENUS}/pages/${request.Page.Id}`, request);
	}

	updateMenuPageName(pageId: string, newPageName: string) {
		return this.http.patch(`${appSettings.APISALONMENU.MENUS}/pages/${pageId}/name`, {
			NewPageName: newPageName,
		});
	}

	updateMenuPageFleepIndex(pageId: string, bookmark: string) {
		return firstValueFrom(
			this.http.patch(`${appSettings.APISALONMENU.MENUS}/pages/${pageId}/fleepindex`, {
				Bookmark: bookmark,
			}),
		);
	}

	updateMenuPageVisibility(pageId: string, isVisible: boolean) {
		return this.http.patch(`${appSettings.APISALONMENU.MENUS}/pages/${pageId}/visibility`, {
			IsVisible: isVisible,
		});
	}

	deleteMenuPageOld(pageId: string) {
		return this.http.delete(`${appSettings.APISALONMENU.MENUS}/pagesold/${pageId}`);
	}

	createMenuItemImageUploadOld(request: ICreateMenuItemImageRequestOld) {
		return this.http.post<IMenuItemImageUpload>(
			`${appSettings.APISALONMENU.MENUS}/pages/${request.MenuPageId}/items/image/upload`,
			request,
		);
	}

	deleteMenuItemImageUploadOld(itemId: number, uploadId: string) {
		return this.http.delete(`${appSettings.APISALONMENU.MENUS}/pages/items/${itemId}/image/upload/${uploadId}`);
	}

	getMenuItemCategoriesFromAllPagesOld(menuId: number, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return this.http.get<IMenuItemCategoryDtoOld[]>(`${appSettings.APISALONMENU.MENUS}/${menuId}/pages/categories`, {
			headers: headers,
		});
	}

	addMenuItemCategoryOld(category: MenuItemCategoryOld) {
		const dto: IMenuItemCategoryDtoOld = {
			Id: category.id,
			MenuPageId: category.menuPageId,
			CategoryId: category.categoryId,
			Order: category.order,
			Name: category.name,
			IdTextActionSede: 0,
			Opacity: 1,
			Price: '',
			Width: 0,
			Height: 0,
			Left: 0,
			Top: 0,
			Text: '',
			Action: '',
			Link: '',
			Color: '',
			Background: '',
			BorderWidth: 0,
			BorderColor: '',
			Services: [],
			PageSelected: '',
		};
		return this.http.post<IMenuItemCategoryDtoOld>(
			`${appSettings.APISALONMENU.MENUS}/pages/${category.menuPageId}/category-items`,
			dto,
		);
	}

	deleteMenuItemCategoryOld(category: MenuItemCategoryOld) {
		return this.http.delete<void>(
			`${appSettings.APISALONMENU.MENUS}/pages/${category.menuPageId}/category-items/${category.id}`,
		);
	}

	addMenuItemServiceOld(service: MenuItemServiceOld) {
		const dto: IMenuItemServiceDtoOld = {
			Id: service.id,
			MenuPageId: service.menuPageId,
			ServiceId: service.serviceId,
			ParentCategoryId: service.categoryId,
			IdTextActionSede: 0,
			Price: service.price,
			Order: service.order,
			Name: service.name,
			Opacity: 1,
			Width: 0,
			Height: 0,
			Left: 0,
			Top: 0,
			Text: '',
			Action: '',
			Link: '',
			Color: '',
			PageSelected: '',
			Background: 0,
			BorderWidth: 0,
			BorderColor: 0,
		};
		return this.http.post<IMenuItemServiceDtoOld>(
			`${appSettings.APISALONMENU.MENUS}/pages/${service.menuPageId}/service-items`,
			dto,
		);
	}

	deleteMenuItemServiceOld(service: MenuItemServiceOld) {
		return this.http.delete<void>(
			`${appSettings.APISALONMENU.MENUS}/pages/${service.menuPageId}/service-items/${service.id}`,
		);
	}

	deleteMenuTextItemOld(itemId: number) {
		return firstValueFrom(this.http.delete(`${appSettings.APISALONMENU.MENUS}/pagesold/text-items/${itemId}`));
	}

	deleteMenuImageItemOld(itemId: number) {
		return firstValueFrom(this.http.delete(`${appSettings.APISALONMENU.MENUS}/pagesold/image-items/${itemId}`));
	}

	updateMenuPageFleepBookmark(pageId: string, bookmark: string) {
		return firstValueFrom(
			this.http.patch(`${appSettings.APISALONMENU.MENUS}/pages/${pageId}/fleepbookmark`, {
				Bookmark: bookmark,
			}),
		);
	}

	getMenuPageById(pageId: string, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return this.http.get<IMenuPageGeneralDto | IMenuPageServiceDto | IMenuPageServiceListDto>(
			`${appSettings.APISALONMENU.MENUS}/pages2/${pageId}`,
			{
				headers: headers,
			},
		);
	}

	getMenuPageServiceById(pageId: string, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return this.http.get<IMenuPageServiceDto>(`${appSettings.APISALONMENU.MENUS}/pages-service/${pageId}`, {
			headers: headers,
		});
	}

	getMenuPageServiceByServiceId(serviceId: number, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return firstValueFrom(
			this.http.get<IMenuPageServiceDto>(`${appSettings.APISALONMENU.MENUS}/pages-service/service/${serviceId}`, {
				headers: headers,
			}),
		);
	}

	getMenuPageServiceListById(pageId: string, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return this.http.get<IMenuPageServiceListDto>(`${appSettings.APISALONMENU.MENUS}/pages-servicelist/${pageId}`, {
			headers: headers,
		});
	}

	getMenuPageGeneralById(pageId: string, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return this.http.get<IMenuPageGeneralDto>(`${appSettings.APISALONMENU.MENUS}/pages-general/${pageId}`, {
			headers: headers,
		});
	}

	updateMenuPageGeneral(request: IUpdateMenuPageGeneralRequest) {
		return firstValueFrom(
			this.http.put<IMenuPageGeneralDto>(`${appSettings.APISALONMENU.MENUS}/pages-general/${request.Page.Id}`, request),
		);
	}

	updateMenuPageService(request: IUpdateMenuPageServiceRequest) {
		return firstValueFrom(
			this.http.put<IMenuPageServiceDto>(`${appSettings.APISALONMENU.MENUS}/pages-service/${request.Page.Id}`, request),
		);
	}

	updateMenuPageServiceList(request: IUpdateMenuPageServiceListRequest) {
		return firstValueFrom(
			this.http.put<IMenuPageServiceListDto>(
				`${appSettings.APISALONMENU.MENUS}/pages-servicelist/${request.Page.Id}`,
				request,
			),
		);
	}

	updateMenuPagePublishStatus(pageId: string, isPublished: boolean) {
		return firstValueFrom(
			this.http.patch(`${appSettings.APISALONMENU.MENUS}/pages/${pageId}/publish`, {
				IsPublished: isPublished,
			}),
		);
	}

	updateMenuPagesOrder(pages: IUpdateMenuPagesOrderInfo[]) {
		return firstValueFrom(
			this.http.patch<void>(`${appSettings.APISALONMENU.MENUS}/pages/update-order`, {
				Pages: pages,
			}),
		);
	}

	deleteMenuPage(pageId: string) {
		return firstValueFrom(this.http.delete(`${appSettings.APISALONMENU.MENUS}/pages/${pageId}`));
	}

	createMenuItemImageUpload(request: ICreateMenuItemImageRequest) {
		return this.http.post<IMenuItemImageUpload>(
			`${appSettings.APISALONMENU.MENUS}/pages2/${request.MenuPageId}/items/image/upload`,
			request,
		);
	}

	deleteMenuItemImageUpload(itemId: number, uploadId: string) {
		return this.http.delete(`${appSettings.APISALONMENU.MENUS}/pages2/items/${itemId}/image/upload/${uploadId}`);
	}

	deleteMenuTextItem(itemId: number) {
		return firstValueFrom(this.http.delete(`${appSettings.APISALONMENU.MENUS}/pages/text-items/${itemId}`));
	}

	deleteMenuImageItem(itemId: number) {
		return firstValueFrom(this.http.delete(`${appSettings.APISALONMENU.MENUS}/pages/image-items/${itemId}`));
	}

	addMenuItemCategory(category: MenuItemServiceCategory) {
		const dto = category.toDto();
		return firstValueFrom(
			this.http.post<IMenuItemServiceCategoryDto>(
				`${appSettings.APISALONMENU.MENUS}/pages-servicelist/${category.menuPageId}/category-items`,
				dto,
			),
		);
	}

	deleteMenuItemCategory(category: MenuItemServiceCategory) {
		return firstValueFrom(
			this.http.delete<void>(
				`${appSettings.APISALONMENU.MENUS}/pages-servicelist/${category.menuPageId}/category-items/${category.id}`,
			),
		);
	}

	addMenuItemService(service: MenuItemService) {
		const dto = service.toDto();
		return firstValueFrom(
			this.http.post<IMenuItemServiceDto>(
				`${appSettings.APISALONMENU.MENUS}/pages-servicelist/${service.menuPageId}/service-items`,
				dto,
			),
		);
	}

	deleteMenuItemService(service: MenuItemService) {
		return firstValueFrom(
			this.http.delete<void>(
				`${appSettings.APISALONMENU.MENUS}/pages-servicelist/${service.menuPageId}/service-items/${service.id}`,
			),
		);
	}

	getMenuItemCategoriesFromAllPages(menuId: number, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return firstValueFrom(
			this.http.get<IMenuItemServiceCategoryDto[]>(`${appSettings.APISALONMENU.MENUS}/${menuId}/pages2/categories`, {
				headers: headers,
			}),
		);
	}

	getHtmlPage(urlHtmlPage: string) {
		// @ts-ignore
		return this.http.get<string>(urlHtmlPage, { responseType: 'text' });
	}
}

export interface IUpdateMenuPagesOrderInfo {
	Id: string;
	Order: number;
	IsGeneralPage: boolean;
	IsServicePage: boolean;
	IsServiceListPage: boolean;
	IsOldPage: boolean;
}
