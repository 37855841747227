import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ItemReorderEventDetail, ModalController} from '@ionic/angular';
import {PhotoI} from '../PhotoI';
import {TemplateService} from '../../../services/template.service';
import {ToastService} from '../../../services/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {TagService} from '../../../services/tag.service';
import {Tag, TagCategory} from '../../../models/tag-category';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit {
    @Input() tags: Tag[];
    @Input() allMacroTags: TagCategory[];
    @Input() isEdit = false;
    @Input() isGallery = true;
    @Input() isModal = true;
    @Input() photo: PhotoI = null;
    @Input() isFiltered: boolean;
    @Input() showFromSede = true;
    @Input() showFromSalone = true;
    @Input() showFromWeb = true;
    @Input() templateId: number;

    cardSelected: Tag[] = [];
    search = '';
    editingMode = false;

    constructor(private modalCtrl: ModalController,
                private alertCtrl: AlertController,
                private translate: TranslateService,
                private templateService: TemplateService,
                private toastService: ToastService,
                private tagService: TagService) {
    }

    ngOnInit() {
        this.cardSelected = JSON.parse(JSON.stringify(this.tags));
    }

    confirm(dismiss = true) {
        if (dismiss) {
            this.modalCtrl.dismiss(null, 'cancel').then();
        } else {
            this.modalCtrl.dismiss({
                    tags: this.cardSelected,
                    showFromSede: this.showFromSede,
                    showFromSalone: this.showFromSalone,
                    showFromWeb: this.showFromWeb,
                    isEdit: this.isEdit
                }
            ).then();
        }
    }

    clickCard(macroTag: TagCategory, item: Tag) {
        const alertInputs = [
            {
                placeholder: 'Tag',
                value: item.Title
            }
        ];

        if (!this.editingMode) {
            if (this.cardSelected.some((el) => el.Id === item.Id)) {
                this.cardSelected = this.cardSelected.filter((el) => el.Id !== item.Id);
            } else {
                this.cardSelected.push(item);
            }
            return;
        }
        this.alertCtrl.create({
            header: 'Modifica tag',
            inputs: alertInputs,
            buttons: [
                {
                    text: 'OK',
                    handler: (value) => {
                        item.Title = value[0];
                        const tagIndex = macroTag.ListTags.findIndex(el => el.Id === item.Id);
                        macroTag[tagIndex] = item;

                        this.tagService.updateTagCategory(macroTag).subscribe({
                            next: (v) => {
                                this.toastService.presentToast(this.translate.instant('tagSaveSuccess'));

                            },
                            error: () => {
                                this.alertCtrl.create({
                                    header: 'Impossibile modificare la categoria',
                                    message: 'La categoria ' + macroTag.Title + ' non può essere modificata.',
                                }).then((alert) => alert.present());
                            }
                        });
                    }
                },
                {
                    text: 'Annulla',
                    role: 'cancel'
                },
                {
                    text: 'Elimina',
                    handler: () => {

                        this.alertCtrl.create({
                            header: 'Conferma eliminazione',
                            message: 'Sei sicuro di voler eliminare il tag ' + item.Title + '?',
                            buttons: [
                                {
                                    text: 'OK',
                                    handler: () => {

                                        const tagIndex = macroTag.ListTags.findIndex(el => el.Id === item.Id);
                                        macroTag.ListTags.splice(tagIndex, 1);
                                        this.tagService.updateTagCategory(macroTag).subscribe({
                                            next: (v) => {
                                                this.toastService.presentToast(this.translate.instant('tagSaveSuccess'));

                                            },
                                            error: () => {
                                                this.alertCtrl.create({
                                                    header: 'Impossibile rimuovere il tag',
                                                    message: 'La categoria ' + macroTag.Title + ' non può essere modificata.',
                                                }).then((alert) => alert.present());
                                            }
                                        });

                                    }
                                },
                                {
                                    text: 'Annulla',
                                    role: 'cancel'
                                }
                            ]
                        }).then(alert => alert.present());

                    }
                },

            ]
        }).then(alert => alert.present());


    }

    getCheck(item: Tag): string {
        return this.cardSelected && this.cardSelected.some(el => el.Id === item.Id)
            ? 'primary'
            : 'secondary';
    }

    sendPhoto() {
        if (!this.photo.Name) {
            if (!this.photo.MetaData.name || !this.photo.MetaData.desc) {
                this.alertCtrl.create({
                    message: this.translate.instant('nameDescRequired'),
                    buttons: [
                        {
                            text: 'Ok',
                        }]
                }).then(alert => alert.present());
                return;
            }
            this.templateService.savePhoto(this.photo.Link, {
                ...this.photo.MetaData,
                // @ts-ignore
                macroTags: JSON.stringify(this.cardSelected)
            })
                .toPromise().then(resp => {
                this.photo.Link = resp.Url;
                this.toastService.presentToast(this.translate.instant('saveSuccess'));
                this.isEdit = false;
                this.modalCtrl.dismiss({tags: this.cardSelected, isEdit: false}).then();
            });
            return;
        }
        let selectedMacroTags = [];
        if (this.cardSelected) {
            selectedMacroTags = this.cardSelected.map(el => el.Id);
        }
        this.photo.MetaData.macroTags = selectedMacroTags;
        // this.httpService.saveMetadata(this.photo).subscribe(resp => {
        //   this.isEdit = false;
        //   this.toastService.presentToast(this.translate.instant('saveSuccess'));
        //   this.modalCtrl.dismiss({macroTags: this.cardSelected, isEdit: false});
        // });
    }

    toggleTagEdit() {
        this.editingMode = !this.editingMode;
    }

    deleteTagCategory(tagCategory: TagCategory) {
        this.alertCtrl.create({
            header: 'Conferma eliminazione',
            message: 'Sei sicuro di voler eliminare la categoria ' + tagCategory.Title + '?',
            buttons: [
                {
                    text: 'OK',
                    handler: () => {
                        this.tagService.deleteTagForTemplate(this.templateId, tagCategory.Id).subscribe({
                            next: () => {
                                this.toastService.presentToast('Categoria eliminata con successo');

                                // Reload the categories
                                this.tagService.getTagCategoriesByTemplateId(this.templateId).subscribe({
                                    next: (newMacros) => {
                                        this.allMacroTags = newMacros;
                                    },
                                });

                            },
                            error: () => {
                                this.alertCtrl.create({
                                    header: 'Impossibile eliminare la categoria',
                                    message: 'La categoria ' + tagCategory.Title + ' non può essere eliminata.',
                                }).then((alert) => alert.present());
                            }
                        });

                    }
                },
                {
                    text: 'Annulla',
                    role: 'cancel'
                }
            ]
        }).then(alert => alert.present());
    }

    updateTagCategory(tagCategory: TagCategory) {
        console.log('updateTagCategory', tagCategory);

        const tag: Tag = {
            Id: 0, Title: ''

        };

        const alertInputs = [
            {
                placeholder: 'Tag',
            }
        ];
        this.alertCtrl.create({
            header: 'Aggiungi nuovo tag',
            inputs: alertInputs,
            buttons: [
                {
                    text: 'OK',
                    handler: (value) => {
                        tag.Title = value[0];
                        tagCategory.ListTags.push(tag);
                        this.tagService.updateTagCategory(tagCategory).subscribe({
                            next: (v) => {
                                this.toastService.presentToast(this.translate.instant('tagSaveSuccess'));
                            },
                            error: () => {
                                this.alertCtrl.create({
                                    header: 'Impossibile Aggiungere nuovo tag',
                                    message: 'La categoria ' + tagCategory.Title + ' non può essere modificata.',
                                }).then((alert) => alert.present());
                            }
                        });
                    },
                },
                {
                    text: 'Annulla',
                    role: 'cancel'
                }
            ]
        }).then(alert => alert.present());
    }

    addNewTagCategory() {
        const newTag: TagCategory = {
            Id: 0, IsFromSede: false, ListTags: [], Title: '', TemplateId: this.templateId
        };

        const alertInputs = [
            {
                placeholder: 'Title',
            },
            {
                placeholder: 'Description',
            }
        ];

        this.alertCtrl.create({
            header: 'Crea nuova categoria',
            inputs: alertInputs,
            buttons: [
                {
                    text: 'OK',
                    handler: (value) => {
                        newTag.Title = value[0];
                        newTag.Description = value[1];
                        this.allMacroTags = [...this.allMacroTags, newTag];
                        this.tagService.newTagCategory(newTag).subscribe({
                            next: (val) => {
                                console.log('newTagCategory', val);

                                this.toastService.presentToast('Categoria creata con successo');

                                // Reload the categories
                                this.tagService.getTagCategoriesByTemplateId(this.templateId).subscribe({
                                    next: (newMacros) => {
                                        this.allMacroTags = newMacros;
                                    },
                                });
                            },
                            error: () => {
                                this.alertCtrl.create({
                                    header: 'Impossibile creare la categoria',
                                    message: 'La categoria ' + newTag.Title + ' non può essere creata.',
                                }).then((alert) => alert.present());
                            }
                        });
                    }
                },
                {
                    text: 'Annulla',
                    role: 'cancel'
                }
            ]
        }).then(alert => alert.present());
    }


    handleMenuItemReorder(ev: CustomEvent<ItemReorderEventDetail>) {
        this.allMacroTags = ev.detail.complete(this.allMacroTags);
        this.allMacroTags.forEach((el, index) => {
            el.Ordering = index;
        });

        console.log(this.allMacroTags);

        this.tagService.updateTagCategoriesOrdering(this.allMacroTags, this.templateId).subscribe({
            next: (v) => {
                this.toastService.presentToast('Ordinamento aggiornato');
            },
            error: () => {
                this.alertCtrl.create({
                    header: 'Impossibile aggiornare l\'ordinamento',
                    message: 'L\'ordinamento delle categorie non può essere aggiornato.',
                }).then((alert) => alert.present());
            }
        });

    }

    editCategory(tagCategory: TagCategory) {
        const alertInputs = [
            {
                placeholder: 'Title',
                value: tagCategory.Title
            },
            {
                placeholder: 'Description',
                value: tagCategory.Description
            }
        ];
        this.alertCtrl.create({
            header: 'Modifica categoria',
            inputs: alertInputs,
            buttons: [
                {
                    text: 'OK',
                    handler: (value) => {
                        tagCategory.Title = value[0];
                        tagCategory.Description = value[1];
                        this.tagService.updateTagCategory(tagCategory).subscribe({
                            next: (v) => {
                                this.toastService.presentToast(this.translate.instant('categorySaveSuccess'));
                            },
                            error: () => {
                                this.alertCtrl.create({
                                    header: 'Impossibile modificare la categoria',
                                    message: 'La categoria ' + tagCategory.Title + ' non può essere modificata.',
                                }).then((alert) => alert.present());
                            }
                        });
                    }
                }
            ]
        }).then(alert => alert.present());
    }

    macroTagsFromTags() {
        return this.allMacroTags.filter(macroTag => this.tags.some(tag => tag.Id === macroTag.Id));
    }
}
