import {Component, Input, OnInit} from '@angular/core';
import {IonToggle, PopoverController} from '@ionic/angular';
import {AuthService} from 'src/app/services/auth.service';
import {PhotoI} from '../../PhotoI';
import {PhotoStateEnum} from '../../PhotoStateEnum';

@Component({
    templateUrl: './visibility-photo.component.html',
    styleUrls: ['./visibility-photo.component.scss'],
})
export class VisibilityPhotoComponent implements OnInit {
    @Input() photo: PhotoI;
    photoStateEnum = PhotoStateEnum;
    blocked = false;
    photoInitial: PhotoI;

    constructor(private viewCtrl: PopoverController, public authService: AuthService) {
    }

    async closeModal() {
        await this.viewCtrl.dismiss(this.photoInitial);
    }

    ngOnInit() {
        this.photoInitial = JSON.parse(JSON.stringify(this.photo));
    }

    change(key: 'galSede' | 'archSal' | 'galSa', checked: boolean, galSede: IonToggle, archSal: IonToggle, galSa: IonToggle) {
        if (!this.blocked) {
            this.blocked = true;
            if (key === 'galSede') {
                if (!checked) {
                    archSal.checked = false;
                    galSa.checked = false;
                    this.photoInitial.MetaData.Stato = this.photoStateEnum.archiveSede;
                } else {
                    archSal.checked = false;
                    this.photoInitial.MetaData.Stato = this.photoStateEnum.gallerySede;
                }
            } else if (key === 'archSal') {
                if (checked) {
                    this.photoInitial.MetaData.Stato = this.photoStateEnum.archiveAll;
                    galSede.checked = false;
                } else {
                    galSa.checked = false;
                    galSede.checked = false;
                    this.photoInitial.MetaData.Stato = this.photoStateEnum.archiveSede;
                }
            } else if (key === 'galSa') {
                if (checked) {
                    galSede.checked = true;
                    archSal.checked = true;
                    this.photoInitial.MetaData.Stato = this.photoStateEnum.galleryAll;
                } else {
                    this.photoInitial.MetaData.Stato = this.photoStateEnum.gallerySede;
                    galSede.checked = true;
                    archSal.checked = false;
                }
            }
            setTimeout(() => {
                this.blocked = false;
            }, 0);
        }
    }
}
