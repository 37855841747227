import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Group } from '../interfaces/group.interface';
import { HttpClient } from '@angular/common/http';
import { appSettings } from '../app-settings';
import { ClientModel } from '../models/cliente.model';
import { catchError } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { LoadingService } from './loading.service';

@Injectable({
	providedIn: 'root',
})
export class GroupsService {
	myGroups$ = new BehaviorSubject<Group[]>([]);
	private sprintf = require('sprintf-js').sprintf;

	constructor(
		private http: HttpClient,
		private toastService: ToastService,
		private loadingService: LoadingService,
	) {}

	getMyGroups() {
		this.http.get(appSettings.APISALONMENU.MY_GROUPS).subscribe((data: Group[]) => {
			this.myGroups$.next(data);
		});
	}

	getGroupById(groupId: string) {
		return this.http.get<Group>(this.sprintf(appSettings.APISALONMENU.GROUP, groupId));
	}

	getClientsByEmail(email: string) {
		return this.http.get<ClientModel[]>(this.sprintf(appSettings.APISALONMENU.CLIENTS_BY_EMAIL, email));
	}

	createGroup(group: Group) {
		return this.http.post(appSettings.APISALONMENU.MY_GROUPS, group);
	}

	editGroup(group: Group) {
		return this.http.put(this.sprintf(appSettings.APISALONMENU.GROUP, group.Id), group);
	}

	deleteGroup(groupId: string) {
		return this.http.delete(this.sprintf(appSettings.APISALONMENU.GROUP, groupId)).pipe(
			catchError((err) => {
				this.toastService.presentToast(err);
				this.loadingService.hide();
				throw err;
			}),
		);
	}
}
