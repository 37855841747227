import {Component, Input, OnInit} from '@angular/core';

import {ModalController} from '@ionic/angular';
import {UsersService} from '../../../../services/users.service';

import {MenusService} from '../../../../services/menus.service';
import {IAlbum} from '../../../../models/IAlbum';
import {TagCategory} from '../../../../models/tag-category';
import {PhotoI} from '../../PhotoI';
import {PhotoStateEnum} from '../../PhotoStateEnum';
import {PhotoDetailComponent} from '../../photo-detail/photo-detail.component';

@Component({
    selector: 'app-album-photos',
    templateUrl: './album-photos.component.html',
    styleUrls: ['./album-photos.component.scss']
})
export class AlbumPhotosComponent implements OnInit {
    @Input() album: IAlbum;
    @Input() isModal = false;
    @Input() search: string;
    @Input() macroTagsSelected: TagCategory[];

    photos: PhotoI[];

    constructor(
        private modalCtrl: ModalController, public usersService: UsersService,
        private menusService: MenusService
    ) {
    }

    get currentUser() {
        return this.usersService.currentUserInfo;
    }

    ngOnInit() {
        this.photos = null;
        const state = (this.menusService.currentMenu.IsSharingAllowed) ? PhotoStateEnum.archiveSede : PhotoStateEnum.archiveAll;
        // this.httpService.getPhotosLibrary(state).pipe(
        //   switchMap(array => array),
        //   map((e) => ({...e, AlbumModel: (!e.AlbumModel ? {Id: null, Nome: '', Anno: ''} : e.AlbumModel)})),
        //   filter(e => e.AlbumModel.Nome === this.album.Nome),
        //   toArray()
        // ).subscribe(photos => this.photos = photos);
    }

    close(photo = null) {
        this.modalCtrl.dismiss(photo);
    }

    clickImage(item: PhotoI, photos: PhotoI[]) {
        if (this.isModal) {
            this.close(item);
            return;
        }
        const indexSelected = photos.findIndex(e => e.Name === item.Name);
        this.modalCtrl.create({
            component: PhotoDetailComponent,
            componentProps: {photos, indexSelected},
            cssClass: 'full-modal'
        }).then((modal) => {
            modal.present();
            modal.onDidDismiss().then(() => {
                this.ngOnInit();
            });
        });
    }

    IsSharingAllowed() {
        return this.menusService.currentMenu.IsSharingAllowed;
    }
}
