import {Component, OnInit} from '@angular/core';
import {AlertController, IonItemSliding, ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {TemplateService} from 'src/app/services/template.service';
import {ToastService} from 'src/app/services/toast.service';
import {UsersService} from 'src/app/services/users.service';
import {TagService} from '../../../services/tag.service';
import {Tag, TagCategory} from '../../../models/tag-category';
import {Utility} from '../../../helpers/Utility';


@Component({
    templateUrl: './category-tags.component.html',
    styleUrls: ['./category-tags.component.scss'],
})
export class CategoryTagsComponent implements OnInit {
    macroTags: TagCategory[] = null;

    constructor(
        public templateService: TemplateService,
        private modalCtrl: ModalController,
        private toastService: ToastService,
        private tagService: TagService,
        public usersService: UsersService,
        private translate: TranslateService,
        private alertCtrl: AlertController
    ) {
    }

    get currentUser() {
        return this.usersService.currentUserInfo;
    }

    ngOnInit() {// TODO WILLIAM
        this.tagService.getMacroTags(0).subscribe(resp => {
            this.macroTags = resp;
        });
    }

    close() {
        this.modalCtrl.dismiss();
    }

    editTag(tag: Tag, IdTagCategory: number, slidingItem: IonItemSliding) {
        const value = (tag) ? tag.Title : '';
        const value2 = (tag) ? tag.Description : '';
        Utility.editAlert(this.alertCtrl, value, value2, this.translate).subscribe((obj: { title: string, description: string }) => {
            if (obj) {
                slidingItem.closeOpened();
                const newTag: Tag = {...tag, Title: obj.title, Description: obj.description, IdTagCategory};
                this.tagService.saveTag(newTag).subscribe(resp => {
                    this.toastService.presentToast(this.translate.instant('saveSuccess'));
                    this.ngOnInit();
                });
            }
        });
    }

    editMacroTag(macroTag: TagCategory, slidingItem: IonItemSliding) {

        const value = (macroTag) ? macroTag.Title : '';
        const value2 = (macroTag) ? macroTag.Description : '';
        Utility.editAlert(this.alertCtrl, value, value2, this.translate).subscribe((obj: { title: string, description: string }) => {
            if (obj) {
                if (slidingItem) {
                    slidingItem.closeOpened();
                }
                const newMacroTag: TagCategory = {...macroTag, Title: obj.title, Description: obj.description};
                this.tagService.saveMacroTag(newMacroTag).subscribe(resp => {
                    this.toastService.presentToast(this.translate.instant('saveSuccess'));
                    this.ngOnInit();
                });
            }
        });
    }

    deleteMacroTag(idMacroTag: number, slidingItem: IonItemSliding) {
        Utility.confirmSave(this.alertCtrl, this.translate.instant('confirmDeleteCategory'), this.translate).subscribe(resp => {
            if (resp) {
                slidingItem.closeOpened();
                this.tagService.deleteMacroTag(idMacroTag).subscribe(() => {
                    this.toastService.presentToast('Eliminazione effettuata con successo');
                    this.ngOnInit();
                });
            }
        });
    }

    deleteTag(idTag: number, slidingItem: IonItemSliding) {
        Utility.confirmSave(this.alertCtrl, this.translate.instant('confirmDeleteCategory'), this.translate).subscribe(resp => {
            if (resp) {
                slidingItem.closeOpened();
                this.tagService.deleteTag(idTag).subscribe(() => {
                    this.toastService.presentToast('Eliminazione effettuata con successo');
                    this.ngOnInit();
                });
            }
        });
    }
}
