import { CatalogItemService, ICatalogItemServiceDto } from "./catalog-item-service";
import { CatalogItemTextFontAlign, CatalogItemTextFontStyle, CatalogItemTextFontWeight } from "./catalog-item-text";
import { CatalogServiceListConfig } from "./catalog-page-service-list";


export interface ICatalogItemServiceCategoryDto{
    Id: number;
    CatalogPageId: string;
    Name: string;
    Order: number;
    Text: string;
    IsVisible: boolean;
    Services: ICatalogItemServiceDto[]
}

export class CatalogItemServiceCategory{
    static create(onModify: (() => void), pageId: string,
                  categoryConfig: CatalogServiceListConfig): CatalogItemServiceCategory{
        var item = new CatalogItemServiceCategory();

        item.onModify = onModify;
        item.catalogPageId = pageId;
        item.config = categoryConfig;

        return item;
    }

    static createFromDto(onModify: (() => void), dto: ICatalogItemServiceCategoryDto,
                         categoryConfig: CatalogServiceListConfig,
                         serviceConfig: CatalogServiceListConfig ): CatalogItemServiceCategory{
        var item = new CatalogItemServiceCategory();

        item.onModify = onModify;

        item.id = dto.Id;
        item.catalogPageId = dto.CatalogPageId;
        item.name = dto.Name;
        item.order = dto.Order;
        item.text = dto.Text;
        item.isVisible = dto.IsVisible;
        item.config = categoryConfig;
        item.services = dto.Services.map(s => CatalogItemService.createFromDto(onModify, s, serviceConfig));

        return item;
    }

    public isNew = false;
    id: number;
    catalogPageId: string;
    name: string;
    order: number;
    text: string;
    isLocked: boolean = false;
    isVisible: boolean = true;
    services: CatalogItemService[] = [];
    config: CatalogServiceListConfig;

    get leftPadding(){ return this.config.leftPadding};
    set leftPadding(value: number){
        this.config.leftPadding = value;
    }
    get bottomPadding(){ return this.config.bottomPadding};
    set bottomPadding(value: number){
        this.config.bottomPadding = value;
    }

    get fontSize(){ return this.config.fontSize};
    set fontSize(value: number){
        this.config.fontSize = value;
    }

    get fontColor(){ return this.config.fontColor};
    set fontColor(value: string){
        this.config.fontColor = value;
    }

    get fontAlign(){ return this.config.fontAlign};
    set fontAlign(value: CatalogItemTextFontAlign){
        this.config.fontAlign = value;
    }

    get fontStyle(){ return this.config.fontStyle};
    set fontStyle(value: CatalogItemTextFontStyle){
        this.config.fontStyle = value;
    }

    get fontWeight(){ return this.config.fontWeight};
    set fontWeight(value: CatalogItemTextFontWeight){
        this.config.fontWeight = value;
    }

    get fontFamily(){ return this.config.fontFamily};
    set fontFamily(value: string){
        this.config.fontFamily = value;
    }

    get lineHeight(){ return this.config.lineHeight};
    set lineHeight(value: number){
        this.config.lineHeight = value;
    }

    get letterSpacing(){ return this.config.letterSpacing};
    set letterSpacing(value: number){
        this.config.letterSpacing = value;
    }

    get backgroundColor(){ return this.config.backgroundColor};
    set backgroundColor(value: string){
        this.config.backgroundColor = value;
    }

    get opacity(){ return this.config.opacity};
    set opacity(value: number){
        this.config.opacity = value;
    }

    get borderWidth(){ return this.config.borderWidth};
    set borderWidth(value: number){
        this.config.borderWidth = value;
    }

    get borderColor(){ return this.config.borderColor};
    set borderColor(value: string){
        this.config.borderColor = value;
    }
    private _isModified: boolean;
    get isModified(){ return this._isModified};
    set isModified(value: boolean){
        this._isModified = value;
        this.onModify();
    }

    private onModify: (() => void);

    private constructor() {

    }

    public toDto(): ICatalogItemServiceCategoryDto{
        return {
            Id: this.id,
            CatalogPageId: this.catalogPageId,
            Name: this.name,
            IsVisible: this.isVisible,
            Order: this.order,
            Text: this.text,
            Services: this.services.map(s => s.toDto())
        }
    }
}
