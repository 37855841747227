import {GalleryFileVModel} from './gallery-file-v.model';
import {PageTemplateGalleryFiles} from './page-template-gallery-files';

export class PageTemplateGalleryFilesVModel {
    templateId: number;
    templateName: string;
    galleryFiles: GalleryFileVModel[] = [];

    constructor(template: PageTemplateGalleryFiles) {
        this.templateId = template.TemplateId;
        this.templateName = template.TemplateName;
        this.galleryFiles = template.GalleryFiles.map(f => new GalleryFileVModel(f));
    }
}
