import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {IAlbum} from '../../../../models/IAlbum';

@Component({
    templateUrl: './edit-album.component.html',
    styleUrls: ['./edit-album.component.scss'],
})
export class EditAlbumComponent implements OnInit {
    @Input() album: IAlbum;

    constructor(private modalCtrl: ModalController) {
    }


    ngOnInit() {

    }

    close(album = null) {
        this.modalCtrl.dismiss(album);
    }
}
