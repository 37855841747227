import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertController, ModalController } from '@ionic/angular';
import { TagsComponent } from './tags/tags.component';
import { PhotoDetailComponent } from './photo-detail/photo-detail.component';
import { UsersService } from '../../services/users.service';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { ArchiveComponent } from './archive/archive.component';
import { TranslateService } from '@ngx-translate/core';
import { TemplateService } from '../../services/template.service';
import { ToastService } from '../../services/toast.service';
import { GalleryService } from '../../services/gallery.service';
import {
	GalleryEditorModalResult,
	GalleryFileEditorComponent,
} from './gallery-file-editor/gallery-file-editor.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AlbumsComponent } from './albums/albums.component';
import { CategoryTagsComponent } from './category-tags/category-tags.component';
import { MenusService } from '../../services/menus.service';
import { TagService } from '../../services/tag.service';
import { GalleryFileVModel } from '../../models/gallery-file-v.model';
import { GalleryFileFormat, GalleryFileModel } from '../../models/gallery-file.model';
import { Tag, TagCategory } from '../../models/tag-category';
import { IAlbum } from '../../models/IAlbum';
import { CategoriaCliente } from '../../models/categoria-cliente.model';
import { Utility } from '../../helpers/Utility';
import { SelectGroupModalComponent } from '../groups/select-group-modal/select-group-modal.component';
import { Group, GroupFeature } from '../../interfaces/group.interface';
import { CatalogPageGeneral } from '../../pages/menu/menu-editor/catalog/models/catalog-page-general';
import { CatalogPageService } from '../../pages/menu/menu-editor/catalog/models/catalog-page-service';
import { CatalogPageServiceList } from '../../pages/menu/menu-editor/catalog/models/catalog-page-service-list';
import { IMenu } from '../../models/menu';

@Component({
	selector: 'app-gallery',
	templateUrl: './gallery.component.html',
	styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
	@ViewChild(ArchiveComponent, { static: false }) archiveComponent: ArchiveComponent;
	@Input() isModal = false;
	@Input() isEdit = false;
	@Input() indexSelected = '0';
	@Output() indexSelectedChange = new EventEmitter<string>();
	@Input() idTemplate: number;
	galleryFiles$: Observable<GalleryFileVModel[]>;
	search = '';
	groupFilter: Group | undefined;
	selectedTags: Tag[] = [];
	albums$: Observable<IAlbum[]>;
	albumSelected: IAlbum = null;
	isSede = false;
	categories: CategoriaCliente[];
	showFromSede = true;
	showFromSalone = true;
	showFromWeb = true;
	templates: IMenu[] = [];
	public isFiltered = false;
	private counter = 0;
	private allMacroTags: TagCategory[];

	constructor(
		private tagService: TagService,
		private router: Router,
		public usersService: UsersService,
		private alertCtrl: AlertController,
		private translate: TranslateService,
		public templateService: TemplateService,
		private toastCtrl: ToastService,
		private galleryService: GalleryService,
		private route: ActivatedRoute,
		private modalCtrl: ModalController,
		private menusService: MenusService,
	) {}

	get currentUser() {
		return this.usersService.currentUserInfo;
	}

	async changeTemplate(template: IMenu) {
		await this.router.navigate(['tabs/gallery/template', template.Id]);
		await this.ngOnInit();
	}

	async ngOnInit() {
		if (!this.idTemplate) {
			this.route.paramMap.subscribe((params: ParamMap) => {
				this.idTemplate = +params.get('templateId');
				if (this.idTemplate === 0) {
					this.idTemplate = null;
				}

				this.tagService.getMacroTags(this.idTemplate).subscribe((allMacroTags) => {
					this.allMacroTags = allMacroTags;
				});

				if (!this.idTemplate) {
					throw Error('idTemplate is Required!');
				}
			});
		} else {
			this.tagService.getMacroTags(this.idTemplate).subscribe((allMacroTags) => {
				this.allMacroTags = allMacroTags;
			});
		}

		if (!this.menusService.currentMenu) {
			this.menusService.currentMenu = await firstValueFrom(this.menusService.getCurrentMenu());
		}

		this.refresh();
		this.albums$ = this.galleryService.getAlbum();
		this.isSede = this.menusService.currentMenu.IsSharingAllowed;

		const readPrivacy = localStorage.getItem('readPrivacy');
		if (!readPrivacy || readPrivacy !== '1') {
			// this.usersService.currentUserInfo.subscribe(user => {
			//   if ([3, 6].includes(user.profiloCliente)) {
			//     // setTimeout(() => {
			//     //   this.modalCtrl.create({
			//     //     component: PrivacyAndPolicyComponent
			//     //   }).then(modal => {
			//     //     modal.present();
			//     //     modal.onDidDismiss().then(result => {
			//     //       if (result.data) {
			//     //         localStorage.setItem('readPrivacy', '1');
			//     //       }
			//     //     });
			//     //   });
			//     // }, 500);
			//   }
			// });
		}

		this.getGalleryFiles().then();

		this.galleryFiles$ = this.galleryService.galleryFiles$;
		this.initMenuItems();
	}

	async getGalleryFiles() {
		const galleryFilesQuery$ = this.galleryService.getGalleryFiles(this.idTemplate, this.groupFilter);

		const galleryFiles$ = galleryFilesQuery$.pipe(
			map((galleryFiles) => galleryFiles.map((f) => new GalleryFileVModel(f))),
			map((galleryFiles) => galleryFiles.filter((f) => !this.isModal || (f.file.IsFinished && !f.file.IsDeleted))),
			tap((galleryFiles) => {
				this.galleryService.setLocalGalleryFiles(galleryFiles);
			}),
		);

		const files = await lastValueFrom(galleryFiles$);

		this.galleryService.setLocalGalleryFiles(files);
	}

	refresh(): void {
		if (this.archiveComponent) {
			this.archiveComponent.refresh();
		}
	}

	readImage() {
		this.alertCtrl
			.create({
				message: this.translate.instant('questionAddImage'),
				buttons: [
					{
						text: 'Immagine',
						handler: () => {
							this.continueAddImage();
						},
					},
					{
						text: 'Video',
						handler: () => {
							this.alertCtrl
								.create({
									message: this.translate.instant('addVideo'),
									inputs: [
										{
											name: 'link',
											placeholder: 'Link',
										},
									],
									buttons: [
										{
											text: this.translate.instant('dismiss'),
											role: 'dismiss',
										},
										{
											text: this.translate.instant('done'),
											handler: (data) => {
												let url = data.link as string;
												if (url) {
													if (url.includes('youtube') && url.includes('?') && !url.includes('embed')) {
														const params = new URLSearchParams('?' + url.split('?')[1]);
														url = 'https://www.youtube.com/embed/' + params.get('v');
													} else if (url.includes('vimeo.com/') && !url.includes('player.vimeo.com')) {
														url = 'https://player.vimeo.com/video/' + url.split('vimeo.com/')[1];
													}
												}
												this.finalConfigPhoto(
													{
														base64String:
															'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAWdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjA76PVpAAAADElEQVQYV2NgYGAAAAAEAAFczf9pAAAAAElFTkSuQmCC',
														format: 'png',
													},
													url,
												);
											},
										},
									],
								})
								.then((alert) => alert.present());
						},
					},
				],
			})
			.then((alert) => alert.present());
	}

	async showAddFileDialog() {
		const alert = await this.alertCtrl.create({
			message: this.translate.instant('questionAddImage'),
			buttons: [
				{
					text: 'Camera',
					handler: () => {
						this.continueAddCameraImage();
					},
				},
				{
					text: 'Immagine',
					handler: () => {
						this.continueAddGalleryImage();
					},
				},
				{
					text: 'Video',
					handler: async () => {
						const alertVideo = await this.alertCtrl.create({
							message: this.translate.instant('addVideo'),
							inputs: [
								{
									name: 'link',
									placeholder: 'Link',
								},
							],
							buttons: [
								{
									text: this.translate.instant('dismiss'),
									role: 'dismiss',
								},
								{
									text: this.translate.instant('done'),
									handler: (data) => {
										let url = data.link as string;
										if (url) {
											if (url.includes('youtube') && url.includes('?') && !url.includes('embed')) {
												const params = new URLSearchParams('?' + url.split('?')[1]);
												url = 'https://www.youtube.com/embed/' + params.get('v');
											} else if (url.includes('vimeo.com/') && !url.includes('player.vimeo.com')) {
												url = 'https://player.vimeo.com/video/' + url.split('vimeo.com/')[1];
											} else if (url.includes('youtube') && url.includes('/shorts/')) {
												url = url.replace('/shorts/', '/embed/');
											}
										}

										this.continueAddGalleryVideo(url);
									},
								},
							],
						});
						alertVideo.present();
					},
				},
			],
		});

		alert.present();
	}

	onGalleryFileClick(file: GalleryFileVModel) {
		if (this.isModal) {
			this.modalCtrl.dismiss(file.toPhotoModel());
		}
	}

	showTags() {
		console.log('open tags');

		this.modalCtrl
			.create({
				component: TagsComponent,
				componentProps: {
					isFiltered: this.isFiltered,
					showFromSede: this.showFromSede,
					showFromSalone: this.showFromSalone,
					showFromWeb: this.showFromWeb,
					tags: this.selectedTags,
					allMacroTags: this.allMacroTags,
					templateId: this.idTemplate,
				},
				cssClass: 'full-modal',
			})
			.then((modal) => {
				modal.present();
				modal.onDidDismiss().then((result) => {
					console.log('tags result', result.data);

					if (result.data) {
						this.selectedTags = JSON.parse(JSON.stringify(result.data.tags));
						this.showFromSede = result.data.showFromSede;
						this.showFromSalone = result.data.showFromSalone;
						this.showFromWeb = result.data.showFromWeb;

						if (this.selectedTags.length > 0 || !this.showFromSede || !this.showFromSalone || !this.showFromWeb) {
							this.isFiltered = true;
						}
					}
				});
			});
	}

	public onClearTags() {
		this.selectedTags = [];
		this.isFiltered = false;
		this.showFromSede = true;
		this.showFromSalone = true;
		this.showFromWeb = true;
	}

	close() {
		this.modalCtrl.dismiss(null, 'dismiss');
	}

	openTags() {
		this.modalCtrl
			.create({
				component: CategoryTagsComponent,
				cssClass: ['full-modal'],
			})
			.then((modal) => {
				modal.present();
			});
	}

	openAlbums() {
		this.modalCtrl
			.create({
				component: AlbumsComponent,
				cssClass: ['full-modal'],
			})
			.then((modal) => {
				modal.present();
				modal.onDidDismiss().then(() => {
					this.refresh();
				});
			});
	}

	abilitateOperator() {
		if (!this.templateService.showAllOperator) {
			this.counter++;
			if (this.counter > 4) {
				this.templateService.showAllOperator = true;
				this.toastCtrl.presentToast('Funzionalità operatore attivate');
			}
		}
	}

	async onPullRefresh(event: any) {
		await this.getGalleryFiles();
		event.target.complete();
	}

	macroTagsFromSelectedTags() {
		return this.allMacroTags.filter((t) => this.selectedTags.some((st) => st.Id === t.Id));
	}

	showGroupFilters() {
		this.modalCtrl
			.create({
				component: SelectGroupModalComponent,
				componentProps: {
					feature: GroupFeature.gallery,
				},
			})
			.then((modal) => {
				modal.present().then();
				modal.onDidDismiss().then((result) => {
					if (result.data && result.data.length > 0) {
						this.groupFilter = result.data[0];
						this.getGalleryFiles().then();
					} else if (result.data && result.data.length === 0) {
						this.groupFilter = undefined;
						this.getGalleryFiles().then();
					}
				});
			});
	}

	selectedTemplate() {
		return this.templates.find((t) => t.Id == this.idTemplate);
	}

	private initMenuItems() {
		this.menusService.getAllMenus(false).subscribe((res) => {
			res.forEach((menuItem) => {
				const allPages = [...menuItem.GeneralPages, ...menuItem.ServicePages, ...menuItem.ServiceListPages].filter(
					(page) => page !== null && page.IsPublished,
				);

				allPages.sort((a, b) => a.Order - b.Order);

				const previewPage = allPages[0];

				if (previewPage) {
					if (previewPage.IsGeneralPage) {
						menuItem.previewType = 'general';
						menuItem.generalPagePreview = CatalogPageGeneral.createFromDto(previewPage as any);
					} else if (previewPage.IsServicePage) {
						menuItem.previewType = 'service';
						menuItem.servicePagePreview = CatalogPageService.createFromDto(previewPage as any);
					} else if (previewPage.IsServiceListPage) {
						menuItem.previewType = 'serviceList';
						menuItem.serviceListPagePreview = CatalogPageServiceList.createFromDto(previewPage as any);
					}
				}
			});

			this.templates = res;
		});
	}

	private continueAddImage() {
		Camera.getPhoto({
			resultType: CameraResultType.Base64,
			source: CameraSource.Prompt,
			quality: 50,
		}).then((capturedPhoto) => {
			this.finalConfigPhoto(capturedPhoto);
		});
	}

	private continueAddCameraImage() {
		Camera.getPhoto({
			resultType: CameraResultType.Uri,
			source: CameraSource.Camera,
			quality: 50,
		}).then(async (capturedPhoto) => {
			const data = await Utility.scaleToFit(capturedPhoto.webPath, 1920);
			const file: GalleryFileModel = {
				Id: 0,
				ClientId: 0,
				IsFavorite: false,
				IsFinished: false,
				IsFromSede: this.showFromSede,
				IsFromSalone: this.showFromSalone,
				ThumbnailUrl: null,
				Url: null,
				TagCategories: [],
				FileFormat: 'jpeg' as GalleryFileFormat,
				FileBase64: data.split(',')[1],
				IsDeleted: false,
				ServiceCategoryIds: [],
				ServiceIds: [],
				TemplateId: 0,
				FileType: 0,
			};

			this.addNewGalleryFile(new GalleryFileVModel(file));
		});
	}

	private continueAddGalleryImage() {
		Camera.pickImages({
			quality: 50,
		}).then((photos) => {
			const base64PhotosPromises = photos.photos.map(
				(p) =>
					new Promise<{ format: string; base64: string }>((resolve) => {
						Utility.scaleToFit(p.webPath, 1920).then((data) =>
							resolve({
								format: p.format,
								base64: data,
							}),
						);
					}),
			);

			Promise.all(base64PhotosPromises).then((base64Photos) => {
				const files: GalleryFileModel[] = base64Photos.map((p) => ({
					Id: 0,
					ClientId: 0,
					IsFavorite: false,
					IsFinished: false,
					IsFromSede: this.showFromSede,
					IsFromSalone: this.showFromSalone,
					ThumbnailUrl: null,
					Url: null,
					TagCategories: [],
					FileFormat: 'jpeg' as GalleryFileFormat,
					FileBase64: p.base64.split(',')[1],
					IsDeleted: false,
					ServiceCategoryIds: [],
					ServiceIds: [],
					TemplateId: 0,
					FileType: 0,
				}));

				this.addNewGalleryFiles(files.map((f) => new GalleryFileVModel(f)));
			});
		});
	}

	private continueAddGalleryVideo(url: string) {
		const file: GalleryFileModel = {
			Id: 0,
			ClientId: 0,
			IsFavorite: false,
			IsFinished: false,
			IsFromSede: this.showFromSede,
			IsFromSalone: this.showFromSalone,
			ThumbnailUrl: null,
			Url: null,
			UrlVideo: url,
			TagCategories: [],
			FileFormat: null,
			FileBase64: null,
			IsDeleted: false,
			ServiceCategoryIds: [],
			ServiceIds: [],
			TemplateId: 0,
			FileType: 1,
		};

		this.addNewGalleryFile(new GalleryFileVModel(file));
	}

	private addNewGalleryFiles(files: GalleryFileVModel[]) {
		if (files.length === 1) {
			this.addNewGalleryFile(files[0]);
			return;
		}

		const galleryFile = new GalleryFileVModel({
			Id: 0,
			ClientId: 0,
			PageTemplateId: this.idTemplate,
			IsFavorite: false,
			IsFinished: false,
			IsFromSede: this.showFromSede,
			IsFromSalone: this.showFromSalone,
			ThumbnailUrl: null,
			Url: null,
			TagCategories: [],
			FileFormat: null,
			FileBase64: null,
			IsDeleted: false,
			ServiceCategoryIds: [],
			ServiceIds: [],
			TemplateId: this.idTemplate,
			FileType: 0,
		});

		this.modalCtrl
			.create({
				component: GalleryFileEditorComponent,
				componentProps: {
					galleryFile,
					isSede: false,
					isNew: true,
					isMultiple: true,
					idTemplate: this.idTemplate,
					newGalleryFiles: files,
				},
				cssClass: 'full-modal',
			})
			.then(async (modal) => {
				modal.present();
				const newFiles = (await modal.onDidDismiss<GalleryFileVModel[]>()).data;
				this.onCreateNewGalleryFiles(newFiles);
			});
	}

	private addNewGalleryFile(file: GalleryFileVModel) {
		this.modalCtrl
			.create({
				component: GalleryFileEditorComponent,
				componentProps: {
					galleryFile: file,
					idTemplate: this.idTemplate,
					isSede: false,
					isNew: true,
				},
				cssClass: 'full-modal',
			})
			.then(async (modal) => {
				modal.present();
				const res = (await modal.onDidDismiss<GalleryEditorModalResult>()).data;
				if (res?.addedFile) {
					this.galleryService.addLocalGalleryFiles([res?.addedFile]);
				}
			});
	}

	private onCreateNewGalleryFiles(files: GalleryFileVModel[]) {
		for (const file of files) {
			file.isProcessing = true;
			file.file.PageTemplateId = this.idTemplate;
			lastValueFrom(this.galleryService.createGalleryFile(file.file))
				.then((res) => {
					file.file.Id = res.Id;
					file.file.IsFinished = res.IsFinished;
					file.file.Url = res.Url;
					file.file.ThumbnailUrl = res.ThumbnailUrl;
					file.file.UrlWebp = res.UrlWebp;
					file.file.UrlVideo = res.UrlVideo;
					file.isProcessing = false;
					file.file.State = res.State;
				})
				.finally(() => {
					file.isProcessing = false;
				});
		}

		this.galleryService.addLocalGalleryFiles(files);
	}

	private finalConfigPhoto(capturedPhoto: Partial<Photo>, urlVideo = '') {
		const newPhotoSrc = 'data:image/' + capturedPhoto.format + ';base64,' + capturedPhoto.base64String;
		// this.compressImage(newPhotoSrc, null, null).then(newPhotoSrc2 => {
		this.modalCtrl
			.create({
				component: PhotoDetailComponent,
				componentProps: { newPhotoSrc, urlVideo },
				cssClass: 'full-modal',
			})
			.then((modal) => {
				modal.present();
				modal.onDidDismiss().then((resp) => {
					if (this.isModal && resp.data) {
						setTimeout(() => {
							this.modalCtrl.dismiss(resp.data);
						}, 0);
					} else {
						this.refresh();
					}
				});
			});
	}
}
