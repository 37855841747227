import { Component, Inject, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AlertController, Platform } from '@ionic/angular';
import { Capacitor, Plugins } from '@capacitor/core';
import { CheckUpdateService } from './services/check-update.service';
import { UsersService } from './services/users.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { FirebaseMessaging } from '@awesome-cordova-plugins/firebase-messaging/ngx';
import { DOCUMENT } from '@angular/common';
import { NotificationService } from './services/notification.service';
import { StorageKeys, StorageService } from './services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { environment } from 'src/environments/environment';
import { PermissionsService } from './services/permissions.service';
import { addIcons } from 'ionicons';
import { Configuration } from './configuration';
import firebase from 'firebase/compat/app';
import { Utility } from './helpers/Utility';

addIcons({
	collaborator: 'assets/svg/collaborator.svg',
	'collaborator-star': 'assets/svg/collaborator-star.svg',
});

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [Globalization],
})
export class AppComponent implements OnInit {
	public environment = environment;
	private renderer2: Renderer2;

	constructor(
		private platform: Platform,
		private router: Router,
		private fcm: FirebaseMessaging,
		public usersService: UsersService,
		public checkUpdateService: CheckUpdateService,
		private statusBar: StatusBar,
		private screenOrientation: ScreenOrientation,
		private googleAnalyticsService: GoogleAnalyticsService,
		private alertCtrl: AlertController,
		private rendererFactory2: RendererFactory2,
		private notificationService: NotificationService,
		private storageService: StorageService,
		private _permissionsService: PermissionsService,
		@Inject(DOCUMENT) private dom: Document,
		private translate: TranslateService,
		private globalization: Globalization,
	) {
		this.renderer2 = rendererFactory2.createRenderer(null, null);
		this.initializeApp();
		this.initializeFirebase();

		Configuration.init().then();
	}

	initializeFirebase() {
		firebase.initializeApp(environment.firebaseConfig);
	}

	initializeApp() {
		this.platform.ready().then(() => {
			this.statusBar.backgroundColorByHexString('#ffffff');
			this.statusBar.overlaysWebView(false);
			if (Capacitor.isPluginAvailable('SplashScreen')) {
				Plugins.SplashScreen.hide();
			}

			if (window.innerWidth < 768) {
				if (Capacitor.getPlatform() !== 'web') {
					this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
				} else {
					window.screen.orientation.lock('portrait');
				}
			}
		});
	}

	ngOnInit() {
		console.log(`%cApp Version: ${environment.appVersion}`, 'color: green');

		this.translate.addLangs(['en', 'it']);

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.googleAnalyticsService.pageView(event.url);
			}
		});
		this.checkUpdateService.checkForUpdates();

		this.storageService.get(StorageKeys.user).then((user) => {
			if (!user) user = sessionStorage.getItem(StorageKeys.user);
			if (user) {
				this.usersService.getCurrentUserInfo().subscribe((user) => {
					let id = user.LinguaDefaultApp;
					const nameLang = Utility.languages.find((e) => e.Id === id);
					this.translate.setDefaultLang(nameLang.Nome.toLowerCase());
					this.translate.use(nameLang.Nome.toLowerCase());
					const permissionCodes = this.usersService.getPermissionCodes();
					this._permissionsService.setPermissions(permissionCodes);

					if (!this.usersService.isCustomer) {
						this.requestNotification();
					}
					// this.appendManifest(user.id);
				});
			} else {
				this.translate.use('it');
				// this.appendManifest();
				if (!location.href.includes('/reset-password') && !location.href.includes('/tos/')) {
					this.router.navigateByUrl('/login');
				}
			}
		});
	}

	private requestNotification() {
		if (Capacitor.getPlatform() !== 'web') {
			this.fcm.requestPermission().then((hasPermission) => {
				if (hasPermission) {
					this.fcm.getToken().then((token) => {
						// tslint:disable-next-line:no-console
						console.info('token', token);
						this.fcm.subscribe('marketingBusiness');
						this.usersService.sendInfo(token).subscribe();
					});

					this.fcm.onTokenRefresh().subscribe(() => {
						this.fcm.getToken().then((token) => {
							this.usersService.sendInfo(token).subscribe();
						});
					});

					this.fcm.onBackgroundMessage().subscribe((data) => {});
					this.fcm.onMessage().subscribe((data) => {
						this.alertCtrl
							.create({
								header: data.title,
								message: data.body,
							})
							.then((alert) => alert.present());
					});
				}
			});
		} else {
			this.usersService.sendInfo().subscribe();
		}
	}

	private appendManifest(idUser: number = null) {
		if (idUser != null) {
			const link = this.renderer2.createElement('link');
			link.href =
				Configuration.getString('wsApiUrl') +
				'/api/manifest/manifest/' +
				idUser +
				'/?url=' +
				encodeURIComponent(location.origin);
			link.rel = 'manifest';
			link.type = 'application/json';

			this.renderer2.appendChild(this.dom.head, link);
		} else {
			const link = this.renderer2.createElement('link');
			link.href =
				Configuration.getString('wsApiUrl') + '/api/manifest/manifest/?url=' + encodeURIComponent(location.origin);
			link.rel = 'manifest';
			link.type = 'application/json';

			this.renderer2.appendChild(this.dom.head, link);
		}
	}

	private formatLanguage(language: string) {
		return language.split('-')[0].toLowerCase();
	}
}
