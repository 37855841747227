
export interface ICatalogItemServiceListContainerDto{
    Name: string;
    Order: number;
    IsFixedHeight: boolean;
    Width: number;
    Height: number;
    Left: number;
    Top: number;
    Gap: number;
    Padding?: number;
    BackgroundColor?: string;
    Opacity?: number;
    BorderWidth?: number;
    BorderColor?: string;
    IsVisible: boolean;
}

export class CatalogItemServiceListContainer{
    static createFromDto(onModify: (() => void), dto: ICatalogItemServiceListContainerDto): CatalogItemServiceListContainer{
        var item = new CatalogItemServiceListContainer();

        item.onModify = onModify;

        item.name = dto.Name;
        item.order = dto.Order;
        item.width = dto.Width;
        item.isFixedHeight = dto.IsFixedHeight;
        item.height = dto.Height;
        item.left = dto.Left;
        item.top = dto.Top;
        item.gap = dto.Gap;
        item.padding = dto.Padding;
        item.backgroundColor = dto.BackgroundColor;
        item.opacity = dto.Opacity;
        item.borderWidth = dto.BorderWidth;
        item.borderColor = dto.BorderColor;
        item.isVisible = dto.IsVisible;

        return item;
    }

    name: string;
    order: number;
    isFixedHeight = false;
    width: number;
    height: number;
    left: number;
    top: number;
    gap: number;
    padding?: number;
    backgroundColor?: string = 'transparent';
    opacity?: number = 1;
    borderWidth?: number = 0;
    borderColor?: string = 'lightgray';
    isVisible: boolean = true;

    private _isModified: boolean;
    get isModified(){ return this._isModified};
    set isModified(value: boolean){
        this._isModified = value;
        this.onModify();
    }

    private onModify: (() => void);

    private constructor() {

    }

    public toDto(): ICatalogItemServiceListContainerDto{
        return {
            Name: this.name,
            BackgroundColor: this.backgroundColor,
            BorderColor: this.borderColor,
            BorderWidth: this.borderWidth,
            IsFixedHeight: this.isFixedHeight,
            Height: Math.round( this.height),
            Top: Math.round( this.top),
            Left: Math.round( this.left),
            Width: Math.round( this.width),
            Gap: Math.round( this.gap),
            Padding: Math.round( this.padding ?? 0),
            IsVisible: this.isVisible,
            Opacity: this.opacity,
            Order: this.order,
        }
    }
}
