import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { appSettings } from 'src/app/app-settings';
import { Metadata } from 'src/app/components/gallery/PhotoI';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { Catalog } from '../components/catalogo/catalog';
import { ServiceGalleryI } from '../components/fleepbook/fleep-page/gallery-listino/ServiceGallery';
import { ISection } from '../interfaces/ISection';
import { ConfigTemplate } from '../models/ConfigTemplate';
import { PagesListino } from '../models/PagesListino';
import { CategoriaCliente } from '../models/categoria-cliente.model';
import { CustomerItem } from '../models/customer-item.model';
import { IMenuTemplatePage } from '../models/menu-template-page';
import {
	InfoTemplateDTO,
	IPagesTemplateConfig,
	PageServizioTemplate,
	PageTemplate,
	SezioneTemplate,
} from '../models/page-template.model';
import { Pagination } from '../models/pagination';
import { Photo, PhotoDTO, TypePhoto } from '../models/photo.model';
import { ServiziCliente } from '../models/servizio-cliente.model';
import { SettingsDTO } from '../pages/menu/settings-listino/settings';
import { TextAction } from '../pages/menu/text-action/text-action';

@Injectable({
	providedIn: 'root',
})
export class TemplateService {
	templateChoose$: BehaviorSubject<ConfigTemplate> = new BehaviorSubject(null);
	config$: Observable<ConfigTemplate> = this.http
		.get<ConfigTemplate>(`${environment.wsApiUrlSalonMenu}/api/PageTemplate/config`)
		.pipe(
			shareReplay(1),
			map((config) => ({ ...config, IdPrimary: config.Id })),
			tap((config) =>
				this.templateChoose$.value === null
					? this.templateChoose$.next({
							...config,
							IdSceltaTemplate: config.IdSceltaTemplate ? config.IdSceltaTemplate : config.Id,
						})
					: null,
			),
		);
	scrollY$: BehaviorSubject<number> = new BehaviorSubject(0);
	valueChange$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	listinoServizi = new BehaviorSubject<CategoriaCliente[]>([]);
	percentWidth: number = null;
	percentHeight: number = null;
	spaceAlignAll = 20;
	showAllOperator = false;
	showFooter$ = new BehaviorSubject<boolean>(true);

	constructor(
		private authService: AuthService,
		private http: HttpClient,
	) {}

	get bundleName(): string {
		return environment.bundleName;
	}

	get appName(): string {
		return environment.appName;
	}

	/****************************  START BACKOFFICE ***************************/

	getTemplates(isSedeTemplate = false, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return this.http.get<PagesListino[]>(
			`${environment.wsApiUrlSalonMenu}/api/PageTemplate/GetSedeTemplates/${isSedeTemplate}`,
			{
				headers: headers,
			},
		);
	}

	sendDefault(id: number) {
		return this.http.put(`${environment.wsApiUrlSalonMenu}/api/PageTemplate/TemplatePrimary/${id}`, null);
	}

	getMenuModelOfClient(isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return this.http.get<ConfigTemplate>(`${environment.wsApiUrlSalonMenu}/api/PageTemplate/config`, {
			headers: headers,
		});
	}

	postTemplate(body: { isVertical: boolean; NomeTemplate: string }) {
		return this.http.post<ConfigTemplate>(`${environment.wsApiUrlSalonMenu}/api/PageTemplate/config`, body);
	}

	deleteTemplate(Id: number) {
		return this.http.delete(`${environment.wsApiUrlSalonMenu}/api/PageTemplate/config/${Id}`);
	}

	putConfig(config: any) {
		return of(this.authService.token).pipe(
			take(1),
			switchMap((token) => {
				return this.http.put(`${environment.wsApiUrlSalonMenu}/api/PageTemplate/config`, config, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
			}),
		);
	}

	// RECUPERO PAGINA SERVIZIO
	deletePage(id: string) {
		return this.http.delete<void>(`${appSettings.APISALONMENU.API}/TemplatePages/${id}`);
	}

	getSezioniTemplate(isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');
		return this.http.get<SezioneTemplate[]>(`${appSettings.APISALONMENU.API}/SezioniTemplate`, {
			headers: headers,
		});
	}

	getAllPagesByIdTemplate(templateId: number, includeTextActions: boolean, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');

		let params = new HttpParams();
		params = params.set('includeTextActions', includeTextActions);

		return this.http.get<PageTemplate[]>(`${appSettings.APISALONMENU.API}/TemplatePages/${templateId}`, {
			headers: headers,
			params: params,
		});
	}

	getTextActionsByPagesTemplateId(pagesTemplateId: string, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');

		return this.http.get<TextAction[]>(`${appSettings.APISALONMENU.API}/TemplatePages/${pagesTemplateId}/TextActions`, {
			headers: headers,
		});
	}

	getPagesTemplateServices(pagesTemplateId: string, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');

		return this.http.get<ServiziCliente[]>(
			`${appSettings.APISALONMENU.API}/TemplatePages/${pagesTemplateId}/Services`,
			{
				headers: headers,
			},
		);
	}

	getPageTemplateServices(pagesTemplateId: number, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) headers = headers.set('pure-request', 'true');

		return this.http.get<ServiziCliente[]>(`${appSettings.APISALONMENU.API}/PageTemplate/${pagesTemplateId}/Services`, {
			headers: headers,
		});
	}

	savePageTemplate(templatePage: PageServizioTemplate | IMenuTemplatePage) {
		templatePage = JSON.parse(JSON.stringify(templatePage));
		if (!templatePage.Id) {
			templatePage.TextActions = templatePage.TextActions.map((e) => ({
				...e,
				Id: typeof e.Id === 'string' ? null : e.Id,
			}));
			let Ordering = 0;
			for (const el of templatePage.TextActions) {
				el.ImagesTemplate = [
					...el.images.map((Url) => ({ Url, IdImageType: 2, Ordering })),
					...el.photos.map((Url) => ({ Url, IdImageType: 1, Ordering })),
				];
				Ordering++;
			}
			return this.http.post<PageServizioTemplate>(`${appSettings.APISALONMENU.API}/TemplatePages`, templatePage);
		}
		const templatePagesModel = JSON.parse(JSON.stringify(templatePage)) as PageTemplate;
		const textActionsModels = templatePagesModel.TextActions.map((e) => ({
			...e,
			Id: typeof e.Id === 'string' ? null : e.Id,
		}));
		delete templatePagesModel.TextActions;
		const body = {
			templatePagesModel,
			textActionsModels,
			idTextToDelete: templatePage.idTextToDelete.filter((e) => typeof e !== 'string'),
		};
		return this.http.put<PageServizioTemplate>(`${appSettings.APISALONMENU.API}/TemplatePages`, body);
	}

	// SEZIONE UPLOAD FILE DROPBOX
	sendPersonalPhoto(body: PhotoDTO, isLogoSalone: boolean) {
		if (isLogoSalone) {
			return this.http.post<Photo>(`${appSettings.API.UPLOAD}/imageToSalone`, body);
		}
		return this.http.post<Photo>(`${appSettings.API.UPLOAD}/image`, body);
	}

	/****************************  END BACKOFFICE ***************************/

	/****************************  SFOGLIABILE ***************************/

	getContentPage(id: number, isSalone: boolean) {
		return this.templateChoose$.pipe(
			switchMap((config) =>
				this.http.get<PagesListino>(
					isSalone
						? `${appSettings.API.PAGE_TEMPLATE}/GetTemplateById?id=${config.Id}`
						: `${appSettings.API.PAGE_TEMPLATE}?id=${id}`,
				),
			),
		);
	}

	savePhoto(
		baseData: string,
		MetaData: Partial<Metadata> = null,
		forceGallery: boolean = null,
		CompressionQuality = 20,
		AllowDifferentFormat = false,
		IdCliente = null,
		menuId?: number,
	) {
		let FileExtension = 'jpeg';
		if (baseData.includes('svg+xml')) {
			FileExtension = 'svg';
			baseData = baseData.split('data:image/svg+xml;charset=utf-8,')[1];
			baseData = decodeURIComponent(baseData);
		} else if (baseData.includes('image/png')) {
			FileExtension = 'png';
		} else if (baseData.includes('image/gif')) {
			FileExtension = 'gif';
		}
		const FileName = 'menudigitale_' + (Math.random() * 1000).toFixed(0);
		let Gallery = MetaData !== null;
		if (forceGallery !== null) {
			Gallery = forceGallery;
		}
		if (MetaData) {
			delete MetaData.webp;
			MetaData.desc = btoa(MetaData.desc);
		}
		if (FileExtension !== 'svg') {
			baseData = baseData.split('base64,')[1];
		}
		const IdTemplate = menuId !== undefined ? menuId : this.templateChoose$.value.Id;
		const body = {
			From: TypePhoto.Personal + '',
			ServiceId: 0,
			HasCustomer: false,
			HasLike: false,
			HasStar: false,
			Description: '',
			FileName,
			FileExtension,
			MetaData,
			Gallery,
			Tags: [],
			IdTemplate,
			Base64Data: baseData,
			CompressionQuality: 100 - CompressionQuality,
			AllowDifferentFormat,
			Compress: CompressionQuality !== 0,
			IdCliente,
		};
		return this.sendPersonalPhoto(body, IdCliente !== null);
	}

	deleteImage(
		LinkImages: string[],
		IdTextAction: string,
		Id: number,
		path: string,
		Field: 'images' | 'photos' = 'images',
	) {
		return this.http.patch(`${appSettings.API.PAGE_IMAGE}/${path}`, { Id, IdTextAction, LinkImages, Field });
	}

	putImage(
		LinkImages: string[],
		IdTextAction: string,
		Id: number,
		path: string,
		Field: 'images' | 'photos' = 'images',
	) {
		return this.http.put(`${appSettings.API.PAGE_IMAGE}/${path}`, { Id, IdTextAction, LinkImages, Field });
	}

	getDuplicatePage(body: { idPagina: string; sezione: string }): Observable<PageTemplate> {
		const params = new URLSearchParams();
		params.set('idPagina', body.idPagina);
		params.set('sezione', body.sezione);

		return this.http.get<PageTemplate>(`${appSettings.API.PAGE_TEMPLATE}/DuplicaPagina?${params.toString()}`);
	}

	getSettings(idPage: string) {
		return this.http.get<SettingsDTO>(`${appSettings.API.PAGE_TEMPLATE_CONFIG}?IdPagesTemplate=${idPage}`);
	}

	saveSettings(settings: Partial<IPagesTemplateConfig>, IdPagesTemplate: string) {
		return this.http.put<SettingsDTO>(`${appSettings.API.PAGE_TEMPLATE_CONFIG}`, { ...settings, IdPagesTemplate });
	}

	getGalleryListino(idTemplate: number) {
		const link = 'fleep/getMetaWithServizi/' + idTemplate;
		return this.http.get<ServiceGalleryI[]>(`${appSettings.APISALONMENU.API}/${link}`);
	}

	getInfoTemplate(menuId: number) {
		return this.http.get<InfoTemplateDTO>(`${appSettings.API.PAGE_TEMPLATE}/GetInfoTemplateById/${menuId}`);
	}

	patchInfoTemplate(templates: IMenuTemplatePage[]) {
		const body = templates.map((e) => ({
			Id: e.Id,
			indexFleep: e.indexFleep,
			pageName: e.PageName,
			Ordering: e.Ordering,
			IsVisibleToCustomers: e.IsVisibleToCustomers,
			IdSezioniTemplate: e.IdSezioniTemplate,
		}));
		return this.http.patch(`${appSettings.APISALONMENU.API}/TemplatePages/InfoTemplate`, body);
	}

	syncPage(pageInfoTemplate: IMenuTemplatePage, value: number, index: number) {
		return this.http.patch(
			`${appSettings.APISALONMENU.API}/sync/${pageInfoTemplate.Id}/${pageInfoTemplate.IsVisibleToCustomers}/${value}/${index}`,
			{},
		);
	}

	syncPagePublish(pageInfoTemplate: IMenuTemplatePage, value: number, index: number) {
		return this.http.patch(
			`${appSettings.APISALONMENU.API}/sync/PublishModifiedPage/${pageInfoTemplate.Id}/${value === 2}/${index}`,
			{},
		);
	}

	getPages(id: number) {
		const link = 'fleep/getAllPages/' + id;
		return this.http.get<PageTemplate[]>(`${appSettings.APISALONMENU.API}/${link}`);
	}

	getPagesByTemplate(id: number) {
		const link = 'TemplatePages/getallVisiblePages/' + id;
		return this.http.get<PageTemplate[]>(`${appSettings.APISALONMENU.API}/${link}`);
	}

	getForm() {
		return this.http.get(`${appSettings.APISALONMENU.API}/report/form`, { responseType: 'blob' });
	}

	getCustomers(pagination: Pagination) {
		return this.http.post<CustomerItem[]>(`${appSettings.API.CUSTOMERS}/GetCustomers`, pagination);
	}

	uploadImage(body: PhotoDTO) {
		return this.http.post<Photo>(`${appSettings.API.UPLOAD}/image`, body);
	}

	reinitConfig(): Observable<ConfigTemplate> {
		this.config$ = this.http.get<ConfigTemplate>(`${environment.wsApiUrlSalonMenu}/api/PageTemplate/config`).pipe(
			map((config) => ({ ...config, IdPrimary: config.Id })),
			tap((config) =>
				this.templateChoose$.value === null
					? this.templateChoose$.next({
							...config,
							IdSceltaTemplate: config.IdSceltaTemplate ? config.IdSceltaTemplate : config.Id,
						})
					: null,
			),
			shareReplay(1),
		);
		return this.config$;
	}

	getCatalog() {
		return this.http.get<Catalog[]>(appSettings.APISALONMENU.CATALOGO);
	}

	getSections() {
		return this.http.get<ISection[]>(appSettings.APISALONMENU.API + '/sections');
	}

	getCatalogById(catalogId: string) {
		return this.http.get<Catalog>(appSettings.APISALONMENU.CATALOGO + '/' + catalogId);
	}

	saveCatalog(pageTemplate: Catalog) {
		const body = JSON.parse(JSON.stringify(pageTemplate)) as Catalog;
		if (body.Id) {
			const textActionsModels = body.TextActions.map((e) => ({
				...e,
				IdCatalogoTemplate: body.Id,
				Id: typeof e.Id === 'string' ? null : e.Id,
			}));
			delete body.TextActions;
			const bodyPut = { templatePagesModel: body, textActionsModels, idTextToDelete: body.idTextToDelete };
			return this.http.put<Catalog>(appSettings.APISALONMENU.CATALOGO, bodyPut);
		} else {
			delete body.Id;
		}
		// @ts-ignore
		body.TextActions = body.TextActions.map((e) => ({ ...e, Id: typeof e.Id === 'string' ? null : e.Id }));
		let Ordering = 0;
		for (const el of body.TextActions) {
			// @ts-ignore
			el.ImagesTemplateCatalogo = [
				...el.images.map((Url) => ({ Url, IdImageType: 2, Ordering })),
				...el.photos.map((Url) => ({ Url, IdImageType: 1, Ordering })),
			];
			Ordering++;
		}
		return this.http.post<Catalog>(appSettings.APISALONMENU.CATALOGO, body);
	}

	deleteCatalog(id: string) {
		return this.http.delete(appSettings.APISALONMENU.CATALOGO + '/' + id);
	}

	updateSection(body: ISection) {
		if (body.Id) {
			return this.http.put<ISection[]>(appSettings.API.API + '/sections', body);
		}
		return this.http.post<ISection[]>(appSettings.API.API + '/sections', body);
	}

	deleteSection(id: number) {
		return this.http.delete(appSettings.API.API + '/sections/' + id);
	}
}
