import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-compress',
  templateUrl: './compress.component.html',
  styleUrls: ['./compress.component.scss'],
})
export class CompressComponent implements OnInit {
  @Input() compressQuality: number;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  close(compressQuality = null) {
    this.modalCtrl.dismiss(compressQuality);
  }
}
