import {Injectable} from '@angular/core';
import {appSettings} from '../app-settings';
import {HttpClient} from '@angular/common/http';
import {Tag, TagCategory} from '../models/tag-category';

@Injectable({
    providedIn: 'root'
})
export class TagService {
    baseUrl = appSettings.APISALONMENU.API;
    salonMenuBaseUrl = appSettings.APISALONMENU.API;

    constructor(private http: HttpClient) {
    }

    getTagCategoriesByTemplateId(templateId: number) {
        return this.http.get<TagCategory[]>(this.baseUrl + '/TagCategories/' + templateId);
    }

    newTagCategory(newTagCategory: TagCategory) {
        return this.http.post(this.baseUrl + '/TagCategories/', newTagCategory);
    }

    updateTagCategory(tagCategory: TagCategory) {
        return this.http.patch(this.baseUrl + '/TagCategories/', tagCategory);
    }

    deleteTagForTemplate(templateId: number, tagCategoryId: number) {
        return this.http.delete<TagCategory[]>(this.baseUrl + '/TagCategories/template/' + templateId + '/' + tagCategoryId);
    }

    updateTagCategoriesOrdering(tagCategories: TagCategory[], templateId: number) {
        return this.http.put(this.baseUrl + `/TagCategories/template/${templateId}`, tagCategories);
    }

    saveTag(newTag: Tag) {
        return newTag.Id ? this.http.patch<TagCategory[]>(this.baseUrl + '/Tags', newTag) :
            this.http.post<TagCategory[]>(this.baseUrl + '/Tags', newTag);
    }

    deleteTag(idTag: number) {
        return this.http.delete<any>(this.baseUrl + '/Tags?id=' + idTag);
    }

    getMacroTags(IdTemplate: number) {
        return this.http.get<TagCategory[]>(this.salonMenuBaseUrl + '/TagCategories/' + IdTemplate);
    }

    saveMacroTag(newMacroTag: TagCategory) {
        return newMacroTag.Id ? this.http.patch<TagCategory[]>(this.baseUrl + '/TagCategories', newMacroTag) :
            this.http.post<TagCategory[]>(this.baseUrl + '/TagCategories', newMacroTag);
    }

    deleteMacroTag(idMacroTag: number) {
        return this.http.delete<any>(this.baseUrl + '/TagCategories?id=' + idMacroTag);
    }
}
