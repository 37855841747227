import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CropperPosition} from 'ngx-image-cropper';
import {ImageComponent} from 'src/app/components/image/image.component';

@Component({
    templateUrl: './image-action.component.html',
    styleUrls: ['./image-action.component.scss'],
})
export class ImageActionComponent {
    @Input() srcImage: string;
    @Input() imageLink: string = null;
    @Input() aspectRatio: number;
    @Input() compressQuality = 20;
    @Input() multiTypeImages = false;
    @Input() position: CropperPosition;
    widthHeight: { width: number; height: number };

    constructor(private modalCtrl: ModalController) {
    }


    close(save = false) {
        let returnValue = null;
        if (save) {
            returnValue = {
                srcImage: this.srcImage, widthHeight: this.widthHeight,
                compressQuality: this.compressQuality, multiTypeImages: this.multiTypeImages
            };
        }
        this.modalCtrl.dismiss(returnValue);
    }

    delete() {
        this.modalCtrl.dismiss({srcImage: ''});
    }

    async save(imageComponent: ImageComponent) {
        await imageComponent.finishCropped();
        setTimeout(() => {
            this.close(true);
        });
    }
}
