import { CatalogItemImage } from "./catalog-item-image";
import { CatalogItemText, CatalogItemTextFontAlign, CatalogItemTextFontStyle, CatalogItemTextFontWeight } from "./catalog-item-text";
import { CatalogPage, ICatalogPageDto } from "./catalog-page";


export interface ICatalogPageServiceDto extends ICatalogPageDto {
    ServiceName: ICatalogPageServiceItemTextDto;
    ServiceDescription: ICatalogPageServiceItemTextDto;
    ServicePrice: ICatalogPageServiceItemTextDto;
    ServiceDuration: ICatalogPageServiceItemTextDto;
    ServicePhotosButton: ICatalogPageServiceItemTextDto;
}

export enum CatalogPageServiceItemTextType{
    Name,
    Description,
    Price,
    Duration,
    PhotosButton
}

export interface ICatalogPageServiceItemTextDto{
    Type: CatalogPageServiceItemTextType;
    CatalogPageId: string;
    Name: string;
    Order: number;
    Width: number;
    Height: number;
    Left: number;
    Top: number;
    Text: string;
    FontSize: number;
    FontColor: string;
    FontAlign: CatalogItemTextFontAlign;
    FontStyle: CatalogItemTextFontStyle;
    FontWeight: CatalogItemTextFontWeight;
    FontFamily: string;
    LineHeight: number;
    LetterSpacing: number;
    BackgroundColor?: string;
    Opacity?: number;
    BorderWidth?: number;
    BorderColor?: string;
}

export class CatalogPageService extends CatalogPage{
    public static createFromDto(dto: ICatalogPageServiceDto){
        var page = new CatalogPageService();

        page.id = dto.Id;
        page.name = dto.Name;
        page.order = dto.Order;
        page.isPublished = dto.IsPublished;
        page.templateId = dto.TemplateId;
        page.imageItems = dto.ImageItems.map(i => CatalogItemImage.createFromDto( () => page.isModified = true, i));
        page.textItems = dto.TextItems.map(t => CatalogItemText.createFromDto(() => page.isModified = true, t))
        page.serviceName = CatalogPageServiceItemText.createFromDto(() => page.isModified = true, dto.ServiceName);
        page.serviceDescription = CatalogPageServiceItemText.createFromDto(() => page.isModified = true, dto.ServiceDescription);
        page.servicePrice = CatalogPageServiceItemText.createFromDto(() => page.isModified = true, dto.ServicePrice);
        page.serviceDuration = CatalogPageServiceItemText.createFromDto(() => page.isModified = true, dto.ServiceDuration);
        page.servicePhotosButton= CatalogPageServiceItemText.createFromDto(() => page.isModified = true, dto.ServicePhotosButton);

        return page;
    }

    private constructor() {
        super();
        this.isServicePage = true;
    }

    serviceName: CatalogPageServiceItemText;
    serviceDescription: CatalogPageServiceItemText;
    servicePrice: CatalogPageServiceItemText;
    serviceDuration: CatalogPageServiceItemText;
    servicePhotosButton: CatalogPageServiceItemText;

    toDto(): ICatalogPageServiceDto{
        const page:ICatalogPageServiceDto =  {
            IsGeneralPage: false,
            IsServiceListPage: false,
            IsServicePage: true,
            Id: this.id,
            Name: this.name,
            Order: this.order,
            IsPublished: this.isPublished,
            TemplateId: this.templateId,
            ServiceName: this.serviceName.toDto(),
            ServiceDescription:  this.serviceDescription.toDto(),
            ServicePrice:  this.servicePrice.toDto(),
            ServiceDuration:  this.serviceDuration.toDto(),
            ServicePhotosButton:  this.servicePhotosButton.toDto(),
            ImageItems: this.imageItems.map(i => i.toDto()),
            TextItems: this.textItems.map(i => i.toDto())
        }
        return page;
    }
}

export class CatalogPageServiceItemText{

    static create(onModify: (() => void), pageId: string): CatalogPageServiceItemText{
        var item = new CatalogPageServiceItemText();

        item.onModify = onModify;
        item.height = 100;
        item.left = 100;
        item.top = 100;
        item.width = 100;
        item.catalogPageId = pageId;

        return item;
    }

    static createFromDto(onModify: (() => void), dto: ICatalogPageServiceItemTextDto): CatalogPageServiceItemText{
        var item = new CatalogPageServiceItemText();

        item.onModify = onModify;

        item.type = dto.Type;
        item.catalogPageId = dto.CatalogPageId;
        item.name = dto.Name;
        item.order = dto.Order;
        item.width = dto.Width;
        item.height = dto.Height;
        item.left = dto.Left;
        item.top = dto.Top;
        item.text = dto.Text;
        item.fontSize = dto.FontSize;
        item.fontColor = dto.FontColor;
        item.fontAlign = dto.FontAlign;
        item.fontStyle = dto.FontStyle;
        item.fontWeight = dto.FontWeight;
        item.fontFamily = dto.FontFamily;
        item.lineHeight = dto.LineHeight;
        item.letterSpacing = dto.LetterSpacing;
        item.backgroundColor = dto.BackgroundColor;
        item.opacity = dto.Opacity;
        item.borderWidth = dto.BorderWidth;
        item.borderColor = dto.BorderColor;

        return item;
    }

    public isNew = false;
    type: CatalogPageServiceItemTextType;
    catalogPageId: string;
    name: string;
    order: number;
    width: number;
    height: number;
    left: number;
    top: number;
    text: string;
    fontSize: number = 14;
    fontColor: string = 'black';
    fontAlign: CatalogItemTextFontAlign = 'left';
    fontStyle: CatalogItemTextFontStyle = 'normal';
    fontWeight: CatalogItemTextFontWeight = 'normal';
    fontFamily: string = 'Comfortaa';
    lineHeight: number = 13;
    letterSpacing: number = 1;
    backgroundColor?: string = 'transparent';
    opacity?: number = 1;
    borderWidth?: number = 0;
    borderColor?: string = 'lightgray';


    private _isModified: boolean;
    get isModified(){ return this._isModified};
    set isModified(value: boolean){
        this._isModified = value;
        this.onModify();
    }

    private onModify: (() => void);

    private constructor() {

    }

    public toDto(): ICatalogPageServiceItemTextDto{
        return {
            Type: this.type,
            CatalogPageId: this.catalogPageId,
            Name: this.name,
            BackgroundColor: this.backgroundColor,
            BorderColor: this.borderColor,
            BorderWidth: this.borderWidth,
            Height: Math.round(this.height),
            Left: Math.round(this.left),
            Opacity: this.opacity,
            Order: this.order,
            Top: Math.round(this.top),
            Width: Math.round(this.width),
            FontSize: this.fontSize,
            FontColor: this.fontColor,
            FontAlign: this.fontAlign,
            FontStyle: this.fontStyle,
            FontWeight: this.fontWeight,
            FontFamily: this.fontFamily,
            LineHeight: this.lineHeight,
            LetterSpacing: this.letterSpacing,
            Text: this.text,
        }
    }
}
