import {Component, OnInit} from '@angular/core';
import {AlertController, IonItemSliding, ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {TemplateService} from 'src/app/services/template.service';
import {ToastService} from 'src/app/services/toast.service';
import {EditAlbumComponent} from './edit-album/edit-album.component';
import {GalleryService} from '../../../services/gallery.service';
import {IAlbum} from '../../../models/IAlbum';
import {Utility} from '../../../helpers/Utility';

@Component({
    templateUrl: './albums.component.html',
    styleUrls: ['./albums.component.scss'],
})
export class AlbumsComponent implements OnInit {
    albums: IAlbum[] = null;

    constructor(public templateService: TemplateService,
                private modalCtrl: ModalController,
                private toastService: ToastService,
                private galleryService: GalleryService,
                private translate: TranslateService,
                private alertCtrl: AlertController) {
    }


    ngOnInit() {
        this.galleryService.getAlbum().subscribe(resp => {
            this.albums = resp.sort((a, b) => (a.Anno + '-' + a.Nome > b.Anno + '-' + b.Nome ? 1 : -1));
        });
    }

    close(data = null) {
        this.modalCtrl.dismiss(data).then();
    }

    editAlbum(album: IAlbum, slidingItem: IonItemSliding) {

        const Nome = (album) ? album.Nome : '';
        const Anno = (album) ? album.Anno : null;
        const Id = (album) ? album.Id : null;
        if (slidingItem) {
            slidingItem.closeOpened();
        }
        const body = {Nome, Anno, Id};
        this.modalCtrl.create({
            component: EditAlbumComponent,
            componentProps: {album: body},
            cssClass: 'full-modal'
        }).then(modal => {
            modal.present();
            modal.onDidDismiss().then(result => {
                if (result.data) {
                    // this.httpService.saveAlbum(result.data).subscribe(resp => {
                    //   this.ngOnInit();
                    // });
                }
            });
        });
    }

    deleteAlbum(Id: string) {
        Utility.confirmSave(this.alertCtrl, this.translate.instant('confirmDeleteAlbum'), this.translate).subscribe(() => {
            this.galleryService.deleteAlbum(Id).subscribe(() => {
                this.toastService.presentToast(this.translate.instant('saveSuccess'));
                this.ngOnInit();
            });
        });
    }
}
