import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { appSettings } from 'src/app/app-settings';
import { PhotoI } from '../components/gallery/PhotoI';
import { map, switchMap, tap, toArray } from 'rxjs/operators';
import { Catalog } from '../components/catalogo/catalog';
import { TemplateService } from './template.service';
import { ServiceGalleryI } from '../components/fleepbook/fleep-page/gallery-listino/ServiceGallery';
import { Configuration } from '../configuration';
import { GalleryFileVModel } from '../models/gallery-file-v.model';
import { PageTemplateGalleryFiles } from '../models/page-template-gallery-files';
import { GalleryFileModel, GalleryFileResult, GalleryFileServiceIds } from '../models/gallery-file.model';
import { IAlbum } from '../models/IAlbum';
import { Group } from '../interfaces/group.interface';
import firebase from 'firebase/compat';

@Injectable({
	providedIn: 'root',
})
export class GalleryService {
	private sprintf = require('sprintf-js').sprintf;

	constructor(
		private http: HttpClient,
		private templateService: TemplateService,
	) {}

	private _galleryFiles$: BehaviorSubject<GalleryFileVModel[]> = new BehaviorSubject<GalleryFileVModel[]>([]);

	public get galleryFiles$() {
		return this._galleryFiles$.asObservable();
	}

	private _archiveFiles$: BehaviorSubject<GalleryFileVModel[]> = new BehaviorSubject<GalleryFileVModel[]>([]);

	public get archiveFiles$() {
		return this._archiveFiles$.asObservable();
	}

	addLocalGalleryFiles(files: GalleryFileVModel[]) {
		this._galleryFiles$.next([...files, ...this._galleryFiles$.value]);
	}

	addLocalArchiveFiles(files: GalleryFileVModel[]) {
		this._archiveFiles$.next([...files, ...this._archiveFiles$.value]);
	}

	removeLocalGalleryFile(file: GalleryFileVModel) {
		this._galleryFiles$.next([...this._galleryFiles$.value.filter((f) => f.file.Id !== file.file.Id)]);
	}

	removeLocalArchiveFile(file: GalleryFileVModel) {
		this._archiveFiles$.next([...this._archiveFiles$.value.filter((f) => f.file.Id !== file.file.Id)]);
	}

	setLocalGalleryFiles(files: GalleryFileVModel[]) {
		this._galleryFiles$.next(files);
	}

	setLocalArchiveFiles(files: GalleryFileVModel[]) {
		this._archiveFiles$.next(files);
	}

	getGalleryFilesForSedeArchive() {
		return this.http.get<PageTemplateGalleryFiles[]>(`${appSettings.APISALONMENU.GALLERY}/sede/archive`);
	}

	getGalleryFilesForSede(pageTemplateId: number) {
		return this.http.get<GalleryFileModel[]>(`${appSettings.APISALONMENU.GALLERY}/sede/${pageTemplateId}`);
	}

	getGalleryFiles(pageTemplateId: number, groupFilter?: Group) {
		const paramsObj: any = {};
		if (groupFilter?.Id != null) {
			paramsObj.clientGroupID = groupFilter.Id.toString();
		}

		return this.http.get<GalleryFileModel[]>(`${appSettings.APISALONMENU.GALLERY}/${pageTemplateId}`, {
			params: new HttpParams({ fromObject: paramsObj }),
		});
	}

	getArchiveFiles(pageTemplateId: number, groupFilter?: Group) {
		const paramsObj: any = {};
		if (groupFilter?.Id != null) {
			paramsObj.clientGroupID = groupFilter.Id.toString();
		}

		return this.http.get<GalleryFileModel[]>(`${appSettings.APISALONMENU.GALLERY}/${pageTemplateId}/files`, {
			params: new HttpParams({ fromObject: paramsObj }),
		});
	}

	getGalleryFileServiceIds(fileId: number) {
		return this.http.get<GalleryFileServiceIds>(`${appSettings.APISALONMENU.GALLERY}/${fileId}/service-ids`);
	}

	createGalleryFile(file: GalleryFileModel) {
		return this.http.post<GalleryFileModel>(`${appSettings.APISALONMENU.GALLERY}`, file);
	}

	updateGalleryFile(file: GalleryFileModel) {
		return this.http.put<GalleryFileModel>(`${appSettings.APISALONMENU.GALLERY}/${file.Id}`, file);
	}

	deleteGalleryFile(file: GalleryFileModel, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) {
			headers = headers.set('pure-request', 'true');
		}

		return this.http.post<GalleryFileResult>(
			`${appSettings.APISALONMENU.GALLERY}/${file.Id}/delete`,
			{},
			{
				headers: headers,
			},
		);
	}

	setIsFavoriteGalleryFile(file: GalleryFileModel, isPureRequest: boolean = false) {
		let headers = new HttpHeaders();
		if (isPureRequest) {
			headers = headers.set('pure-request', 'true');
		}

		return this.http.patch<GalleryFileResult>(
			`${appSettings.APISALONMENU.GALLERY}/${file.Id}/set-is-favorite/${file.IsFavorite}`,
			{},
			{
				headers: headers,
			},
		);
	}

	getFile(url: string) {
		// @ts-ignore
		return this.http.get<string>(url, { responseType: 'blob' });
	}

	getAlbum() {
		return this.http.get<IAlbum[]>(appSettings.APISALONMENU.API + '/album');
	}

	getPhotosLibrary(state: number, allGallery = false) {
		const idTemplate = this.templateService.templateChoose$.value.IdSceltaTemplate;
		return this.http
			.get<{
				Files: PhotoI[];
			}>(appSettings.API.API + '/upload/listimage/' + idTemplate + '/' + state + '/' + allGallery)
			.pipe(
				map((e) => e.Files),
				switchMap((array) => array),
				tap((e) => {
					if (!e.MetaData) {
						e.MetaData = {};
					}
				}),
				tap((e) => (e.MetaData.macroTags = e.MetaData.macroTags ? JSON.parse(e.MetaData.macroTags + '') : [])),
				tap((e) => {
					try {
						e.MetaData.desc = e.MetaData.desc ? atob(e.MetaData.desc) : '';
					} catch (error) {
						e.MetaData.desc = '';
					}
				}),
				toArray(),
			);
	}

	getPhotosLibraryVetrina(state: number, allGallery = false) {
		const idTemplate = this.templateService.templateChoose$.value.IdSceltaTemplate;
		return (
			this.http
				// @ts-ignore
				.get<{ Files: PhotoI[] }>(this.baseUrl + '/upload/listimage/' + idTemplate + '/' + state + '/' + allGallery)
				.pipe(map((e) => e.Files))
		);
	}

	saveAlbum(album: IAlbum) {
		if (album.Id) {
			return this.http.put<Catalog>(appSettings.API.API + '/album', album);
		} else {
			delete album.Id;
		}
		return this.http.post<Catalog>(appSettings.API.API + '/album', album);
	}

	deleteAlbum(id: string) {
		return this.http.delete(appSettings.API.API + '/album/' + id);
	}

	patchImage(item: PhotoI) {
		const { Name, changeImage } = item;
		let base64 = changeImage.base64;
		base64 = base64.split('base64,')[1];
		return this.http.put<any>(appSettings.API.API + '/upload/updateImage', { fileName: Name, base64 });
	}

	deleteImage(item: PhotoI, IdTemplate) {
		const { Link } = item;
		return this.http.post<any>(appSettings.API.API + '/upload/deleteimage', { Link, IdTemplate });
	}

	getGalleryListino(idTemplate: number) {
		const link = 'fleep/getMetaWithServizi/' + idTemplate;
		return this.http.get<ServiceGalleryI[]>(`${Configuration.getString('wsApiUrl')}/api/${link}`);
	}

	editFileGroups(photoId: string | number, groupIds: string[], isArchive: boolean) {
		const url = isArchive
			? appSettings.APISALONMENU.EDIT_ARCHIVE_FILE_GROUPS
			: appSettings.APISALONMENU.EDIT_GALLERY_FILE_GROUPS;

		return this.http.put(this.sprintf(url, photoId), {
			ClientGroups: groupIds,
		});
	}

	getGroupsAssignedTo(fileId: number, isArchive: boolean) {
		const url = isArchive
			? appSettings.APISALONMENU.EDIT_ARCHIVE_FILE_GROUPS
			: appSettings.APISALONMENU.EDIT_GALLERY_FILE_GROUPS

		return this.http.get<Group[]>(this.sprintf(url, fileId));
	}
}
