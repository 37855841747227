import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GroupsService } from '../../../services/groups.service';
import { Group, GroupFeature } from '../../../interfaces/group.interface';
import { Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-filter-by-group-modal',
	templateUrl: './select-group-modal.component.html',
	styleUrls: ['./select-group-modal.component.scss'],
})
export class SelectGroupModalComponent implements OnInit, OnDestroy {
	@Input() forAssigning = false;
	@Input() feature?: GroupFeature;
	@Input() selectedGroups: Group[] = [];
	groups: Group[] = [];

	private myGroupsSub: Subscription | undefined;

	constructor(
		private groupsService: GroupsService,
		private modalCtrl: ModalController,
	) {}

	ngOnInit(): void {
		if (this.groupsService.myGroups$.value.length === 0) {
			this.groupsService.getMyGroups();
		}

		this.myGroupsSub = this.groupsService.myGroups$.subscribe((groups) => {
			this.groups = groups;

			if (this.feature !== undefined) {
				this.groups = this.groups.filter((group) => group.Feature === this.feature);
			}
		});
	}

	ngOnDestroy() {
		this.myGroupsSub?.unsubscribe();
	}

	async close() {
		await this.modalCtrl.dismiss();
	}

	toggleSelectGroup(group: Group) {
		const index = this.selectedGroups.findIndex((c) => c.Id === group.Id);
		if (index === -1) {
			this.selectedGroups.push(group);
		} else {
			this.selectedGroups.splice(index, 1);
		}
	}

	groupIsSelected(group: Group): boolean {
		return this.selectedGroups.some((c) => c.Id === group.Id);
	}

	async save() {
		await this.modalCtrl.dismiss(this.selectedGroups);
	}

	async selectGroup(group: Group) {
		await this.modalCtrl.dismiss([group]);
	}

	async removeSelection() {
		await this.modalCtrl.dismiss([]);
	}
}
