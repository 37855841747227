import { ClientModel } from '../models/cliente.model';

export interface Group {
	Id: string;
	Name: string;
	Feature: GroupFeature;
	Clients: ClientModel[];
	ResourceAssigned: boolean;
}

export enum GroupFeature {
	menupages = 0,
	gallery = 1,
	archive = 2,
	seminars = 3,
	catalog = 4,
	services = 5,
}
