export const environment = {
	appVersion: require('../../package.json').version + '-ahs',
	production: false,
	smsGateway: '6Aq7',
	smsUser: 'digitalev',
	smsPassword: '6Aq7Ob/t47M',
	separatorChips: '#;',
	wsApiUrl: 'https://salonmenu-test.osb.solutions/wsSA',
	wsApiUrlSalonMenu: 'https://salonmenu-test.osb.solutions/wsSM',
	hostName: 'https://apisalonapp.osb.solutions/',
	pageItemForsList: 10,
	googleAnalyticsKey: '',
	paginationLength: 50,
	appName: 'ArtHairStudios',
	bundleName: 'ahs',
	bundleDomain: '.digitalevolutionitalia.it',

	firebaseConfig: {
		apiKey: 'AIzaSyB7X2NHKfISddzYTOt4mb935W0wZYdRXus',
		authDomain: 'booking-online-208b6.firebaseapp.com',
		projectId: 'booking-online-208b6',
		storageBucket: 'booking-online-208b6.appspot.com',
		messagingSenderId: '405410337723',
		appId: '1:405410337723:web:37b09f14c209b48c1eaa9e',
		measurementId: 'G-PFHSV5C9M9',
	},
	bottomTabs: ['calendario', 'gallery', 'menu', 'archive', 'seminars'],
	topTabs: [
		'template-settings',
		'menu-collaborators',
		'menu-price-list',
		'menu-warehouse',
		'menu-promotions',
		'menu-checkapp',
		'menu-users',
		'menu-tenants',
		'menu-support',
		'menu-profile',
		'menu-report-fiche',
	],
	navigationCards: [
		{
			name: 'gallery',
			image: 'https://www.arthairstudiosbywella.it/Content/images/AHS_header.jpg',
		},
		{
			name: 'menu',
			image: 'https://www.arthairstudiosbywella.it/Content/images/collezione_img2.jpg',
		},
		{
			name: 'seminars',
			image: 'https://www.arthairstudiosbywella.it/Content/images/eventi_img_left.jpg',
		},
	],
	qualityImage: 10,
	serviceMaxCharacter: 40,
	serviceDescriptionMaxCharacter: 400,
	// TODO: download the google-services.json and GoogleService-Info.plist from the firebase console
};
